import React, { memo, useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  ChatRoot,
  ChatContRowLeft,
  ChatContRowRight,
  TextWrap,
} from "./TranscriptChat.styled";

export interface TranscriptChatProps {
  lead_id: string;
}

export interface ILoading {
  success: boolean;
  message: string;
}

type Nullable<T> = T | undefined | null;

export interface TranscriptChatEntity {
  channel: Nullable<number>;
  text: Nullable<string>;
  start: Nullable<number>;
  end: Nullable<number>;
}

const TranscriptChat = ({ lead_id }: TranscriptChatProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Nullable<string>>(null);
  const [agent, setAgent] = useState<Nullable<string>>(null);
  const [data, setData] = useState<TranscriptChatEntity[]>([]);

  const prevLeadId = useRef<number>(0);
  useEffect(() => {
    const leadId: number = parseInt(lead_id);
    if (leadId && leadId > 0) {
      if (!prevLeadId.current || prevLeadId.current !== leadId) {
        prevLeadId.current = leadId;
        setLoading(true);
        setError(null);
        axios
          .get(
            `https://devanalytics.genba.tech/api/genba/awaken/transcription/${leadId}`,
            {
              headers: {
                Authorization: `Bearer 0fb74d02-eb78-45d0-85fe-2d8ea6985d39`,
              },
            },
          )
          .then(resp => {
            const { data } = resp;
            if (data.result) {
              setAgent(data.result.client_data.agentid);
              setData(data.result.transcript);
            }
            setLoading(false);
          })
          .catch(ex => {
            setError(ex.message);
            setLoading(false);
          });
        // setData([{
        //   lead_id: lead_id,
        //   history_id: 29127513,
        //   call_start: '01/08/2023 13:42:40',
        //   call_end: '01/08/2023 13:42:56',
        //   direction: 'Inbound',
        //   agent: 'Anton',
        //   max: 'INHU',
        //   dispoistion: 'Inbound',
        //   recording_url: 'http://www.sousound.com/music/healing/healing_01.mp3'
        // }])
      }
    }
  }, [setLoading, lead_id]);

  return (
    <>
      {loading ? <span>Loading...</span> : null}
      {error ? <span>{error}</span> : null}
      <CallTable agent={agent} data={data} />
    </>
  );
};

interface CallTableProps {
  agent: Nullable<string>;
  data: TranscriptChatEntity[];
}

const CallTable = ({ agent, data }: CallTableProps): JSX.Element => {
  return (
    <ChatRoot>
      {data.map((r, i) => (
        <ChatRowEl key={i} agent={agent} data={r} />
      ))}
    </ChatRoot>
  );
};

interface ChatRowProps {
  agent: Nullable<string>;
  data: TranscriptChatEntity;
}

const ChatRowEl = memo(function ChatRow({ agent, data }: ChatRowProps) {
  const ChatContRow = data.channel === 0 ? ChatContRowLeft : ChatContRowRight;
  return (
    <div>
      <ChatContRow>
        {/* <div style={{textAlign: data.channel === 0 ? 'left' : 'right', paddingRight: 12}}>{data.channel === 0 ? <span style={{paddingRight: 12}}>{agent}</span> : ''}{data.start} - {data.end}</div> */}
        <div
          style={{
            textAlign: data.channel === 0 ? "left" : "right",
            paddingRight: 12,
          }}
        >
          {data.start} - {data.end}
        </div>
      </ChatContRow>
      <ChatContRow>
        <div
          style={{
            maxWidth: "90%",
            borderRadius: 5,
            padding: 8,
            marginBottom: 8,
            color: "#fff",
            backgroundColor: data.channel === 0 ? "#69AAE6" : "#C7C7C7",
          }}
        >
          <TextWrap>{data.text}</TextWrap>
        </div>
      </ChatContRow>
    </div>
  );
});

// eslint-disable-next-line import/no-default-export -- deprecated usage
export default React.memo(TranscriptChat);
