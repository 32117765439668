var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var ffa,gfa,hfa,ifa,jfa,kfa,lfa,UG,mfa,nfa,ofa,pfa,qfa,rfa,sfa,tfa,WG,ufa;$CLJS.PG=new $CLJS.M(null,"supported-field","supported-field",-2061545519);ffa=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.QG=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);gfa=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
hfa=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.RG=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$CLJS.SG=new $CLJS.M(null,"column-name","column-name",551523580);ifa=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.TG=new $CLJS.M(null,"display-info","display-info",-816930907);jfa=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);
kfa=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);lfa=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);UG=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);mfa=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);nfa=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);ofa=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);
pfa=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);qfa=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);rfa=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);sfa=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.VG=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);tfa=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);
WG=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);$CLJS.XG=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);ufa=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.EF($CLJS.Wx,$CLJS.H([$CLJS.ot,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)],null)],null)],null)]));$CLJS.EF($CLJS.oG,$CLJS.H([$CLJS.ot,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)],null)],null)],null)]));
$CLJS.CF($CLJS.hG,$CLJS.H([$CLJS.ot,$CLJS.TD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null)]));$CLJS.CF($CLJS.TF,$CLJS.H([$CLJS.ot,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.cF],null)],null)]));$CLJS.CF($CLJS.wG,$CLJS.H([$CLJS.ot,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));
$CLJS.CF($CLJS.lk,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)]));$CLJS.vF($CLJS.lk,$CLJS.hF);$CLJS.CF($CLJS.BG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null)]));$CLJS.vF($CLJS.BG,$CLJS.hF);$CLJS.CF($CLJS.ao,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)]));$CLJS.vF($CLJS.ao,$CLJS.hF);
$CLJS.X(UG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Es,new $CLJS.h(null,1,[$CLJS.vt,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.CF($CLJS.ZF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UG],null)]));$CLJS.vF($CLJS.ZF,$CLJS.hF);
$CLJS.CF($CLJS.uG,$CLJS.H([$CLJS.ot,$CLJS.TD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));$CLJS.CF($CLJS.DG,$CLJS.H([$CLJS.ot,$CLJS.TD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null)]));$CLJS.CF($CLJS.AG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null)]));
$CLJS.CF($CLJS.KG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null)]));$CLJS.vF($CLJS.AG,$CLJS.hF);$CLJS.vF($CLJS.KG,$CLJS.hF);$CLJS.CF($CLJS.IG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)]));$CLJS.vF($CLJS.IG,$CLJS.hF);
$CLJS.CF($CLJS.UF,$CLJS.H([$CLJS.ot,$CLJS.TD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nF],null)],null)]));$CLJS.X(WG,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.ys,tfa,rfa,kfa,gfa,ffa,jfa,lfa,mfa,nfa,qfa,ofa,ufa,sfa,hfa,pfa,$CLJS.Fl],null));$CLJS.X($CLJS.QG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.ao,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WG],null)],null));
$CLJS.YG=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.gG,$CLJS.Wx,$CLJS.RG,!1,$CLJS.VG,$CLJS.IF,$CLJS.TG,function(){return new $CLJS.h(null,3,[$CLJS.sE,$CLJS.PE("Count of rows"),$CLJS.SG,$CLJS.PE("Count"),$CLJS.Pt,$CLJS.PE("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.gG,$CLJS.AG,$CLJS.PG,$CLJS.hk,$CLJS.RG,!0,$CLJS.VG,$CLJS.IF,$CLJS.TG,function(){return new $CLJS.h(null,3,[$CLJS.sE,$CLJS.PE("Sum of ..."),$CLJS.SG,$CLJS.PE("Sum"),$CLJS.Pt,$CLJS.PE("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.gG,$CLJS.hG,$CLJS.PG,$CLJS.hk,$CLJS.RG,!0,$CLJS.VG,$CLJS.IF,$CLJS.TG,function(){return new $CLJS.h(null,3,[$CLJS.sE,$CLJS.PE("Average of ..."),$CLJS.SG,$CLJS.PE("Average"),$CLJS.Pt,$CLJS.PE("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.gG,$CLJS.BG,$CLJS.PG,$CLJS.hk,$CLJS.RG,!0,$CLJS.VG,$CLJS.jG,$CLJS.TG,function(){return new $CLJS.h(null,3,[$CLJS.sE,$CLJS.PE("Median of ..."),$CLJS.SG,$CLJS.PE("Median"),$CLJS.Pt,$CLJS.PE("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.gG,$CLJS.TF,$CLJS.PG,$CLJS.Ms,$CLJS.RG,!0,$CLJS.VG,$CLJS.IF,$CLJS.TG,function(){return new $CLJS.h(null,3,[$CLJS.sE,$CLJS.PE("Number of distinct values of ..."),$CLJS.SG,$CLJS.PE("Distinct values"),$CLJS.Pt,$CLJS.PE("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.gG,$CLJS.KG,$CLJS.PG,$CLJS.hk,$CLJS.RG,!0,$CLJS.VG,$CLJS.IF,$CLJS.TG,function(){return new $CLJS.h(null,3,[$CLJS.sE,$CLJS.PE("Cumulative sum of ..."),
$CLJS.SG,$CLJS.PE("Sum"),$CLJS.Pt,$CLJS.PE("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.gG,$CLJS.oG,$CLJS.RG,!1,$CLJS.VG,$CLJS.IF,$CLJS.TG,function(){return new $CLJS.h(null,3,[$CLJS.sE,$CLJS.PE("Cumulative count of rows"),$CLJS.SG,$CLJS.PE("Count"),$CLJS.Pt,$CLJS.PE("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.gG,$CLJS.DG,$CLJS.PG,$CLJS.hk,$CLJS.RG,
!0,$CLJS.VG,$CLJS.xG,$CLJS.TG,function(){return new $CLJS.h(null,3,[$CLJS.sE,$CLJS.PE("Standard deviation of ..."),$CLJS.SG,$CLJS.PE("SD"),$CLJS.Pt,$CLJS.PE("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.gG,$CLJS.ao,$CLJS.PG,$CLJS.Zi,$CLJS.RG,!0,$CLJS.VG,$CLJS.IF,$CLJS.TG,function(){return new $CLJS.h(null,3,[$CLJS.sE,$CLJS.PE("Minimum of ..."),$CLJS.SG,$CLJS.PE("Min"),$CLJS.Pt,$CLJS.PE("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.gG,$CLJS.lk,$CLJS.PG,$CLJS.Zi,$CLJS.RG,!0,$CLJS.VG,$CLJS.IF,$CLJS.TG,function(){return new $CLJS.h(null,3,[$CLJS.sE,$CLJS.PE("Maximum of ..."),$CLJS.SG,$CLJS.PE("Max"),$CLJS.Pt,$CLJS.PE("Maximum value of a column")],null)}],null)],null);
$CLJS.X(ifa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.XG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gG,$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.vs],null),$CLJS.hf.h($CLJS.gG),$CLJS.YG)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PG,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xs,$CLJS.Yi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RG,$CLJS.Ls],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.VG,$CLJS.Yi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TG,$CLJS.md],null)],null));