import React, { memo, useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  TableRoot,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHeader,
  StatusBoxYes,
  StatusBoxNo,
  StatusBoxUnclear,
  QaRow,
  StatusBox,
  StatusCont,
  InlineCont,
  Divider,
} from "./CallQaTable.styled";

export interface CallQaTableProps {
  source: string;
  lead_id: string;
}

export interface ILoading {
  success: boolean;
  message: string;
}

type Nullable<T> = T | undefined | null;

export interface CallQaTableEntity {
  question: Nullable<string>;
  answer: Nullable<string>;
  boolean: Nullable<string>;
}

const CallQaTable = ({ source, lead_id }: CallQaTableProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Nullable<string>>(null);
  const [data, setData] = useState<CallQaTableEntity[]>([]);

  const prevLeadId = useRef<number>(0);
  useEffect(() => {
    const leadId: number = parseInt(lead_id);
    if (leadId && leadId > 0) {
      if (!prevLeadId.current || prevLeadId.current !== leadId) {
        prevLeadId.current = leadId;
        setLoading(true);
        setError(null);

        let sourceUrl = null;
        let sourceToken = null;
        switch (source) {
          case "csi":
            sourceUrl = `https://csi-api.genbacx.com/api/integration/csi/comms/qa/standard/${leadId}`;
            sourceToken = "1f7b6f64-013a-4e14-90e3-1463eb4cf853";
            break;
          default:
            sourceUrl = `https://m3-dev.53dnorth.com/storm/external/integration/csi/qa/standard/${leadId}`;
            sourceToken = "e430647f-6715-4c40-bda9-03a1e6a4090f";
            break;
        }
        if (!sourceUrl) {
          return;
        }

        axios
          .get(sourceUrl, {
            headers: {
              Authorization: `Bearer ${sourceToken}`,
            },
          })
          .then(resp => {
            const { data } = resp;
            setData(data.answers);
            setLoading(false);
          })
          .catch(ex => {
            setError(ex.message);
            setLoading(false);
          });
      }
    }
  }, [setLoading, lead_id, source]);

  return (
    <>
      {loading ? <span>Loading...</span> : null}
      {error ? <span>{error}</span> : null}
      <CallTable lead_id={lead_id} data={data} />
    </>
  );
};

interface CallQaProps {
  lead_id: Nullable<string>;
  data: CallQaTableEntity[];
}

const CallTable = ({ lead_id, data }: CallQaProps): JSX.Element => {
  return (
    <TableRoot>
      <TableHeader>
        {/* <TableHeaderRow>
          <TableHeaderCell>{t`Question`}</TableHeaderCell>
          <TableHeaderCell>{t`Answer`}</TableHeaderCell>
          <TableHeaderCell>{t`Boolean`}</TableHeaderCell>
        </TableHeaderRow> */}
      </TableHeader>
      <TableBody>
        {data.map((r, i) => (
          <CallQaRow key={`${lead_id}_${i}`} data={r} />
        ))}
      </TableBody>
    </TableRoot>
  );
};

interface CallQaRowProps {
  data: CallQaTableEntity;
}

const CallQaRow = memo(function CallQaRow({ data }: CallQaRowProps) {
  // data.question[0] = data.question[0].toUpperCase
  data.question = data.question || "";
  return (
    <TableBodyRow>
      <TableBodyCell>
        <div style={{ display: "flex" }}>
          <QaRow>
            <StatusBox>Q</StatusBox> {data.question[0].toUpperCase()}
            {data.question.slice(1)} {data.boolean}
          </QaRow>
          {data.boolean ? (
            <StatusCont>
              <StatusBoxYes opacity={data.boolean === "yes" ? 1 : 0.2} />
              <StatusBoxUnclear
                opacity={data.boolean === "unclear" ? 1 : 0.2}
              />
              <StatusBoxNo opacity={data.boolean === "no" ? 1 : 0.2} />
            </StatusCont>
          ) : null}
        </div>
        <div style={{ display: "flex", marginTop: 4 }}>
          <InlineCont>
            <StatusBox>A</StatusBox>
          </InlineCont>
          <InlineCont>{data.answer}</InlineCont>
        </div>
        <Divider />
      </TableBodyCell>
    </TableBodyRow>
  );
});

// eslint-disable-next-line import/no-default-export -- deprecated usage
export default React.memo(CallQaTable);
