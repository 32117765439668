import styled from "@emotion/styled";

export const FieldLabel = styled.label`
  display: flex;
  margin-bottom: 0.5rem;

  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16.002px;
  text-transform: uppercase;
  line-height: 1.602em;

  color: #4c5773;
`;
