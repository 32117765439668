import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  Ref,
  forwardRef,
} from "react";
import axios from "axios";
import moment from "moment";
import { t } from "ttag";
import * as Yup from "yup";

// import Button from "metabase/core/components/Button";

// old import 
// import Form from "metabase/core/components/Form";
// import FormProvider from "metabase/core/components/FormProvider";
// import FormSubmitButton from "metabase/core/components/FormSubmitButton";
// import FormErrorMessage from "metabase/core/components/FormErrorMessage";

import {
  Form,
  FormErrorMessage,
  FormProvider,
  FormSubmitButton,
} from "metabase/forms";

// import FormTextAreaWidget from "metabase/core/components/FormTextArea";
import type { InputComponentType } from "metabase-types/api";

import { ActionFormFieldWidget } from "../../../../actions/components/ActionFormFieldWidget";
import { ActionFormButtonContainer } from "../../../../actions/components/ActionForm/ActionForm.styled";
import FormTextAreaWidget from "./FormTextArea/FormTextArea";
import { FieldLabel } from "./FeedbackForm.styled";

// import {
// } from "./FeedbackForm.styled";

const validationSchema = Yup.object({
  id: Yup.number(),
  processed_reason: Yup.string().default(""),
  manager_notes: Yup.string().default(""),
});

export interface FeedbackFormProps {
  source: string;
  lead_id: string;
}

export interface ILoading {
  success: boolean;
  message: string;
}

type Nullable<T> = T | undefined | null;

export interface FeedbackFormEntity {
  channel: Nullable<number>;
  text: Nullable<string>;
  start: Nullable<number>;
  end: Nullable<number>;
}

interface FormWidgetProps {
  formField: any;
  hidden?: boolean;
  actions?: any;
}

const ActionFormFieldTextarea = forwardRef(function FormFieldWidget(
  { formField, hidden, actions }: FormWidgetProps,
  ref: Ref<any>,
) {
  return (
    <FormTextAreaWidget
      {...formField}
      disabled={hidden}
      ref={ref}
      styleTextArea={{
        fontFamily: "var(--default-font-family), sans-serif !important",
        fontSize: 12.65,
        fontWeight: "normal",
        height: 250,
      }}
    />
  );
});

const FeedbackForm = ({ source, lead_id }: FeedbackFormProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Nullable<string>>(null);
  const [processedAt, setProcessedAt] = useState<Nullable<string>>(null);
  const [feedbackFinal, setFeedbackFinal] = useState<Nullable<number>>(null);
  const [feedbackNotesAt, setFeedbackNotesAt] =
    useState<Nullable<string>>(null);
  const [processedReason, setProcessedReason] =
    useState<Nullable<string>>(null);

  const handleSubmit = useCallback(
    values => {
      let sourceUrl = null;
      let sourceToken = null;
      switch (source) {
        case "csi":
          sourceUrl = `https://csi-api.genbacx.com/api/integration/csi/feedback/${lead_id}`;
          sourceToken = "1f7b6f64-013a-4e14-90e3-1463eb4cf853";
          break;
        default:
          sourceUrl = `https://m3-dev.53dnorth.com/storm/external/integration/feedback/${lead_id}`;
          sourceToken = "e430647f-6715-4c40-bda9-03a1e6a4090f";
          break;
      }
      setLoading(true);
      setError(null);
      axios
        .post(sourceUrl, values, {
          headers: {
            Authorization: `Bearer ${sourceToken}`,
          },
        })
        .then(resp => {
          setLoading(false);
        })
        .catch(ex => {
          setError(ex.message);
          setLoading(false);
        });
    },
    [lead_id, source],
  );

  // const [ agent, setAgent ] = useState<Nullable<string>>(null)
  const [_, setData] = useState<FeedbackFormEntity[]>([]);

  const formNotesRef = useRef(null);
  const formStatusRef = useRef(null);
  const prevLeadId = useRef<number>(0);
  useEffect(() => {
    const leadId: number = parseInt(lead_id);
    if (leadId && leadId > 0) {
      if (!prevLeadId.current || prevLeadId.current !== leadId) {
        prevLeadId.current = leadId;

        let sourceUrl = null;
        let sourceToken = null;
        switch (source) {
          case "csi":
            sourceUrl = `https://csi-api.genbacx.com/api/integration/csi/feedback/${leadId}`;
            sourceToken = "1f7b6f64-013a-4e14-90e3-1463eb4cf853";
            break;
          default:
            sourceUrl = `https://m3-dev.53dnorth.com/storm/external/integration/feedback/${leadId}`;
            sourceToken = "e430647f-6715-4c40-bda9-03a1e6a4090f";
            break;
        }
        if (!sourceUrl) {
          return;
        }

        setLoading(true);
        setError(null);
        axios
          .get(sourceUrl, {
            headers: {
              Authorization: `Bearer ${sourceToken}`,
            },
          })
          .then(resp => {
            const { data } = resp;
            setData(data.result);
            if (
              formNotesRef.current &&
              data.result &&
              data.result.feedback_notes
            ) {
              setFeedbackNotesAt(
                moment(data.result.feedback_notes_at).format(
                  "DD/MM/YYYY HH:mm:ss",
                ),
              );
              formNotesRef.current.setFieldValue(
                "manager_notes",
                data.result.feedback_notes,
              );
            }
            if (
              formStatusRef.current &&
              data.result &&
              data.result.processed_reason
            ) {
              setFeedbackFinal(data.result.feedback_final);
              setProcessedReason(data.result.processed_reason);
              setProcessedAt(
                moment(data.result.processed_at).format("DD/MM/YYYY HH:mm:ss"),
              );
              formStatusRef.current.setFieldValue(
                "processed_reason",
                data.result.processed_reason,
              );
            }
            setLoading(false);
          })
          .catch(ex => {
            setError(ex.message);
            setLoading(false);
          });
      }
    }
  }, [setLoading, lead_id, source]);

  const managerNotesFields = [
    {
      name: "manager_notes",
      title: `Manager Notes ${
        feedbackNotesAt ? `- Submitted ${feedbackNotesAt}` : ""
      }`,
      type: "textarea" as InputComponentType,
    },
  ];

  const updateStatusFields = [
    {
      name: "processed_reason",
      title: `Status ${processedAt ? `- Submitted ${processedAt}` : ""}`,
      type: "select" as InputComponentType,
      options: [
        // { name: t`Feedback Status`, value: "" },
        // { name: t`Contacted/Investigating`, value: "investigating" },
        // { name: t`Awaiting Customer Callback`, value: "awaiting_callback" },
        // { name: t`Resolved`, value: "closed" },
        // { name: t`Escalated`, value: "escalated_internally" },
        { name: t`Feedback Status`, value: "" },
        { name: t`Query Resolved`, value: "Query Resolved" },
        { name: t`Escalated Internally`, value: "Escalated Internally" },
        { name: t`Escalated Ford CRC`, value: "Escalated Ford CRC" },
        { name: t`Investigating`, value: "Investigating" },
        { name: t`Awaiting Callback`, value: "Awaiting Callback" },
        { name: t`Closed`, value: "Closed" },
      ],
    },
  ];

  return (
    <>
      {loading ? <span>Loading...</span> : null}
      {error ? <span>{error}</span> : null}
      <div
        style={{
          display: !processedReason || !feedbackFinal ? "none" : "block",
          paddingBottom: 12,
        }}
      >
        <h3 style={{ color: "green" }}>
          Final Status: {processedReason}{" "}
          {processedAt ? `- ${processedAt}` : ""}
        </h3>
      </div>
      <FormProvider
        initialValues={{
          manager_notes: "",
        }}
        innerRef={formNotesRef}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        <Form role="form" data-testid="action-form">
          {managerNotesFields.map(field => {
            //  formField={{...field, title: null}}
            return (
              <div key={`${field.name}_${lead_id}`}>
                <FieldLabel>{field.title}</FieldLabel>
                {field.type === "textarea" ? (
                  <ActionFormFieldTextarea
                    key={field.name}
                    formField={{ ...field, title: null }}
                  />
                ) : (
                  <ActionFormFieldWidget
                    key={field.name}
                    formField={{ ...field, title: null }}
                  />
                )}
              </div>
            );
          })}
          <ActionFormButtonContainer style={{ justifyContent: "flex-start" }}>
            <FormSubmitButton
              {...{
                title: "Save Notes",
              }}
              success={true}
              successTitle="Update Status"
              disabled={loading}
            />
          </ActionFormButtonContainer>

          <FormErrorMessage />
        </Form>
      </FormProvider>
      <div
        style={{ display: feedbackFinal ? "none" : "block", paddingTop: 12 }}
      >
        <FormProvider
          initialValues={{
            processed_reason: "",
          }}
          innerRef={formStatusRef}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          <Form
            role="form"
            data-testid="action-form"
            style={{ display: "flex", alignItems: "center" }}
          >
            {updateStatusFields.map(field => {
              //  formField={{...field, title: null}}
              return (
                <div key={`${field.name}_${lead_id}`}>
                  <FieldLabel>{field.title}</FieldLabel>
                  {field.type === "textarea" ? (
                    <ActionFormFieldTextarea
                      key={field.name}
                      formField={{ ...field, title: null }}
                    />
                  ) : (
                    <ActionFormFieldWidget
                      key={field.name}
                      formField={{ ...field, title: null }}
                    />
                  )}
                </div>
              );
            })}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexGrow: 1,
                paddingLeft: 12,
                paddingTop: 14,
              }}
            >
              <FormSubmitButton
                {...{
                  title: "Update Status",
                }}
                primary={true}
                disabled={loading}
                style={{ height: 39 }}
              />
            </div>
            <FormErrorMessage />
          </Form>
        </FormProvider>
      </div>
    </>
  );
};

// eslint-disable-next-line import/no-default-export -- deprecated usage
export default React.memo(FeedbackForm);
