var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var hV,iV,jV,lV,mV,nV,oV,pV,qV,rV;hV=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);iV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);jV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.kV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);lV=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);mV=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);nV=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
oV=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);pV=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);qV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);rV=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.X(hV,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,new $CLJS.h(null,1,[$CLJS.vt,"Valid template tag :widget-type"],null),$CLJS.jA],null),$CLJS.Xg($CLJS.cV)));$CLJS.X(lV,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.vs,$CLJS.LM,$CLJS.AN,$CLJS.HF,$CLJS.Xt,$CLJS.AB,$CLJS.GP],null));
$CLJS.X(oV,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.xE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sE,$CLJS.xE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,$CLJS.xE,$CLJS.ek],null)],null)],null));
$CLJS.X(pV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Fl],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bO,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.Ls],null)],null)],null));
$CLJS.X(mV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.HF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gj,new $CLJS.h(null,1,[$CLJS.Qs,!0],
null),$CLJS.kk],null)],null)],null));
$CLJS.X(nV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.LM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xM,$CLJS.xE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YP,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.kL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZP,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.cL],null)],null)],null));
$CLJS.X(rV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.AN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hR,$CLJS.bL],null)],null)],null));$CLJS.X(qV,$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.vs],null),$CLJS.RU));
$CLJS.X(iV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qV],null)],null)],null)],null));
$CLJS.X(jV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lV],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,1,[$CLJS.Oi,$CLJS.nj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AN,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bp,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iV],null)],null)],null)],null));
$CLJS.X($CLJS.kV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,$CLJS.xE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.vt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Qe(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.T.h(b))},a)}],null)],null));