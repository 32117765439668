import styled from "@emotion/styled";
import { css } from "@emotion/react";
// import { color } from "metabase/lib/colors";

// const cellStyles = css`
//   padding-left: 1.5rem;
//   padding-right: 1.5rem;
//   white-space: pre-wrap;
// `;

const globalStyles = css`
  font-family: var(--default-font-family), sans-serif;
`;

const rowStyles = css`
  ${globalStyles}
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
  padding: 0.75rem;
  width: 100%;

  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.13);
  background-color: #fff;

  margin-bottom: 12px;

  font-size: 1.143em;
  line-height: 1.602em;
`;

export const TimelineCardCont = styled.div`
  ${globalStyles}
  ${rowStyles}
`;

export const TimelineCardTitle = styled.span`
  ${globalStyles}
  color: #509EE3;
  font-size: 16px;
  margin-left: 4px;
  margin-right: 4px;
`;

export const TimelineCardSubTitle = styled.span`
  ${globalStyles}
  font-size: 16px;
  font-weight: bold;
  margin-left: 4px;
  margin-right: 4px;
`;
