import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { color } from "metabase/lib/colors";

const globalStyles = css`
  font-family: var(--default-font-family), sans-serif;
  font-size: 1em;
`;

export const TabsContainer = styled.div`
  ${globalStyles}
  margin-top: -20px;
  padding: 0 1.5rem;
  border-bottom: 1px solid ${color("border")};
`;

export const ContentContainer = styled.div`
  margin-top: 1.5rem;
  padding: 0 1.5rem;
`;
