/* eslint-disable react/prop-types */
import React, { useState } from "react";

const IFrame = ({ url }) => {
  const [height, setHeight] = useState(150);
  const getHeight = r => {
    if (!r.parentElement) {
      return;
    }
    if (r.parentElement.parentElement.offsetHeight) {
      setHeight(r.parentElement.parentElement.offsetHeight);
    }
  };
  return (
    <iframe
      ref={r => {
        if (!r) {
          return;
        }
        r.onload = () => {
          getHeight(r);
          r.parentElement.onresize = () => {
            getHeight(r);
          };
        };
      }}
      src={url}
      style={{
        width: "100%",
        height: height - 40,
        border: "none",
      }}
    ></iframe>
  );
};

export default React.memo(IFrame);
