import React, { memo, useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  TimelineCardCont,
  TimelineCardTitle,
  TimelineCardSubTitle,
} from "./TimelineCards.styled";

export interface TimelineProps {
  lead_id: string;
}

export interface ILoading {
  success: boolean;
  message: string;
}

type Nullable<T> = T | undefined | null;

export interface TimelineEntity {
  type: Nullable<string>;
  description: Nullable<string>;
  result_code: Nullable<string>;
  created_at: Nullable<string>;
  icon: Nullable<string>;
}

const TimelineCards = ({ lead_id }: TimelineProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Nullable<string>>(null);
  const [data, setData] = useState<TimelineEntity[]>([]);

  const prevLeadId = useRef<number>(0);
  useEffect(() => {
    const leadId: number = parseInt(lead_id);
    if (leadId && leadId > 0) {
      if (!prevLeadId.current || prevLeadId.current !== leadId) {
        prevLeadId.current = leadId;
        setLoading(true);
        setError(null);
        axios
          .get(
            `https://m3-dev.53dnorth.com/storm/external/integration/lead/timeline/${leadId}`,
            {
              headers: {
                Authorization: `Bearer e430647f-6715-4c40-bda9-03a1e6a4090f`,
              },
            },
          )
          .then(resp => {
            const { data } = resp;
            setData(data.results);
            setLoading(false);
          })
          .catch(ex => {
            setError(ex.message);
            setLoading(false);
          });
      }
    }
  }, [setLoading, lead_id]);

  return (
    <>
      {loading ? <span>Loading...</span> : null}
      {error ? <span>{error}</span> : null}
      <TimelineTable data={data} />
    </>
  );
};

interface TimelineTableProps {
  data: TimelineEntity[];
}

const TimelineTable = ({ data }: TimelineTableProps): JSX.Element => {
  return (
    <div>
      {data.map((r, i) => (
        <TimelineCard key={`timeline_${i}`} index={i} data={r} />
      ))}
    </div>
  );
};

interface TimelineCardProps {
  index: number;
  data: TimelineEntity;
}

const TimelineCard = memo(function Call({ index, data }: TimelineCardProps) {
  // console.log(data, 'data')
  return (
    <TimelineCardCont>
      <img src={data.icon || ""} alt={data.type || ""} width={25} />
      <TimelineCardSubTitle>{data.created_at}</TimelineCardSubTitle>
      <TimelineCardTitle>
        {data.description}
        {data.result_code ? ` - ${data.result_code}` : ""}
      </TimelineCardTitle>
    </TimelineCardCont>
  );
});

// eslint-disable-next-line import/no-default-export -- deprecated usage
export default React.memo(TimelineCards);
