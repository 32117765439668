import { useState, memo } from "react";

import Radio from "metabase/core/components/Radio";

import { TabsContainer, ContentContainer } from "./Tabs.styled";

type TabsOwnProps = {
  titles: ToolTab[];
  children: React.ReactNode[];
};

type TabsStateProps = {
  isModelPersistenceEnabled?: boolean;
};

type TabsDispatchProps = {
  navigateToTab: (tab: string) => void;
};

type Props = TabsOwnProps & TabsStateProps & TabsDispatchProps;

type ToolTab = {
  name: string;
  value: string;
};

function Tabs({ titles, children }: Props) {
  const [currentTab, setCurrentTab] = useState(titles[0].value || null);
  const tabs: ToolTab[] = [...titles];

  return (
    <>
      {tabs.length > 1 && (
        <TabsContainer>
          <Radio
            // colorScheme="accent7"
            value={currentTab}
            options={tabs}
            onOptionClick={setCurrentTab}
            variant="underlined"
          />
        </TabsContainer>
      )}
      <ContentContainer>
        {children.map((c, i) => {
          return (
            <div
              key={i}
              style={{
                display: tabs[i].value === currentTab ? "block" : "none",
              }}
            >
              {c}
            </div>
          );
        })}
      </ContentContainer>
    </>
  );
}

// eslint-disable-next-line import/no-default-export -- deprecated usage
export default memo(Tabs);
