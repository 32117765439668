var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.schema.common.js");require("./metabase.mbql.predicates.js");require("./metabase.mbql.schema.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var CV;$CLJS.xV=function(a){return a instanceof Error?a.message:null};
$CLJS.yV=function(a,b,c){if($CLJS.Zd(c)){var d=$CLJS.Se.g($CLJS.U,$CLJS.hf.g(a,c));return b.h?b.h(d):b.call(null,d)}return $CLJS.zf(c)?(d=new $CLJS.Yf(function(){var e=$CLJS.Eb(c);return a.h?a.h(e):a.call(null,e)}(),function(){var e=$CLJS.Fb(c);return a.h?a.h(e):a.call(null,e)}()),b.h?b.h(d):b.call(null,d)):$CLJS.Dd(c)?(d=$CLJS.mh($CLJS.hf.g(a,c)),b.h?b.h(d):b.call(null,d)):$CLJS.yd(c)?(d=$CLJS.fb(function(e,f){return $CLJS.be.g(e,a.h?a.h(f):a.call(null,f))},c,c),b.h?b.h(d):b.call(null,d)):$CLJS.ud(c)?
(d=$CLJS.eg.g($CLJS.jd(c),$CLJS.hf.g(a,c)),b.h?b.h(d):b.call(null,d)):b.h?b.h(c):b.call(null,c)};$CLJS.AV=function(a){function b(c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);return"string"===typeof d?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zh.h(d),c],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null)}return $CLJS.zV(function(c){return $CLJS.xd(c)?$CLJS.eg.g($CLJS.N,$CLJS.hf.g(b,c)):c},a)};
$CLJS.BV=function(a){var b=new $CLJS.Ea;for(a=$CLJS.y(a);;)if(null!=a)b=b.append($CLJS.p.h($CLJS.A(a))),a=$CLJS.B(a);else return b.toString()};$CLJS.DV=function(a,b,c){a=$CLJS.IA(a,/''/,"'");var d=$CLJS.Vt(a,CV);d=$CLJS.E.g($CLJS.D(d),1)?new $CLJS.P(null,2,5,$CLJS.Q,[a,""],null):d;a=$CLJS.oh(/.*\{0\}.*/,a);return $CLJS.At.ngettext($CLJS.At.msgid($CLJS.ll(d),$CLJS.n(a)?c:""),$CLJS.IA($CLJS.IA(b,/''/,"'"),CV,$CLJS.p.h(c)),c)};
$CLJS.EV=function(a,b){return $CLJS.fb(function(c,d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.Hd(a,e)?$CLJS.R.j(c,d,$CLJS.J.g(a,e)):c},$CLJS.Se.j($CLJS.Jk,a,$CLJS.Xg(b)),b)};$CLJS.FV=function(a,b,c){return $CLJS.xd(c)?$CLJS.jf(function(d){var e=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);e=$CLJS.be.g(b,e);return a.g?a.g(e,d):a.call(null,e,d)},$CLJS.H([c])):$CLJS.wd(c)?$CLJS.jf($CLJS.Ve(a,$CLJS.A(c)instanceof $CLJS.M?$CLJS.be.g(b,$CLJS.A(c)):b),$CLJS.H([c])):null};
$CLJS.zV=function zV(a,b){return $CLJS.yV($CLJS.Ve(zV,a),a,b)};CV=/\{0\}/;$CLJS.GV=new $CLJS.M(null,"unique-alias-fn","unique-alias-fn",32555420);$CLJS.HV=new $CLJS.M(null,"binning-strategy","binning-strategy",2063329158);$CLJS.IV=new $CLJS.M("clojure.core.match","not-found","clojure.core.match/not-found",1553053780);$CLJS.JV=new $CLJS.M(null,"name-key-fn","name-key-fn",-1634839199);var Hka=$CLJS.Ig([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$I,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mJ,$CLJS.fJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pJ,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TI,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qJ,null],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.mJ,$CLJS.XI],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mJ,$CLJS.kJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZI,null],null)],[$CLJS.Ai,$CLJS.eH,$CLJS.xJ,$CLJS.cJ,$CLJS.vi,$CLJS.Hj,$CLJS.jj,$CLJS.ok,$CLJS.Gi,$CLJS.cJ,$CLJS.yJ,$CLJS.bH]),LV;$CLJS.fh($CLJS.hf.g($CLJS.A,$CLJS.Xg(Hka)));var KV,Ika=$CLJS.Xe($CLJS.N),Jka=$CLJS.Xe($CLJS.N),Kka=$CLJS.Xe($CLJS.N),Lka=$CLJS.Xe($CLJS.N),Mka=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
KV=new $CLJS.di($CLJS.Fh.g("metabase.mbql.util","negate*"),$CLJS.A,Mka,Ika,Jka,Kka,Lka);KV.m(null,$CLJS.As,function(a){$CLJS.I(a,0,null);return $CLJS.I(a,1,null)});KV.m(null,$CLJS.Es,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);return $CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ys],null),$CLJS.hf.g(KV,a))});KV.m(null,$CLJS.ys,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);return $CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Es],null),$CLJS.hf.g(KV,a))});
KV.m(null,$CLJS.bs,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FG,b,a],null)});KV.m(null,$CLJS.FG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bs,b,a],null)});KV.m(null,$CLJS.Vr,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.as,b,a],null)});
KV.m(null,$CLJS.Zr,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Xr,b,a],null)});KV.m(null,$CLJS.Xr,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zr,b,a],null)});KV.m(null,$CLJS.as,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vr,b,a],null)});
KV.m(null,$CLJS.QF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zr,b,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vr,b,a],null)],null)});KV.m(null,$CLJS.mG,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.As,a],null)});KV.m(null,$CLJS.eG,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.As,a],null)});
KV.m(null,$CLJS.yG,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.As,a],null)});
LV=function LV(a){return $CLJS.xd(a)?$CLJS.Fe($CLJS.eg.g($CLJS.N,function(){return function e(d){return new $CLJS.ne(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=LV.h?LV.h(u):LV.call(null,u);null!=u&&m.add(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null));t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);
m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=LV.h?LV.h(k):LV.call(null,k);if(null!=k)return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[m,k],null),e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(a)}())):$CLJS.wd(a)?$CLJS.Fe($CLJS.eg.g($CLJS.jd(a),$CLJS.lf($CLJS.Wa,$CLJS.hf.g(LV,a)))):a};
$CLJS.MV=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var k=Array(arguments.length-2);f<k.length;)k[f]=arguments[f+2],++f;f=new $CLJS.w(k,0,null)}return b.call(this,c,d,f)}function b(c,d,e){var f=$CLJS.I(c,0,null),k=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);d=$CLJS.Fe(LV($CLJS.Se.j(d,c,e)));return $CLJS.n(d)?new $CLJS.P(null,3,5,$CLJS.Q,[f,k,d],null):$CLJS.E.g(f,$CLJS.nG)?new $CLJS.P(null,3,5,$CLJS.Q,[f,k,null],null):new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}a.A=2;a.B=function(c){var d=
$CLJS.A(c);c=$CLJS.B(c);var e=$CLJS.A(c);c=$CLJS.Lc(c);return b(d,e,c)};a.l=b;return a}();