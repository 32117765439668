import React, { memo, useEffect, useState, useRef } from "react";
import { t } from "ttag";
import axios from "axios";
import {
  TableRoot,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHeader,
  TableHeaderCell,
  TableHeaderRow,
} from "./CallHistory.styled";

export interface CallHistoryProps {
  source: string;
  lead_id: string;
}

export interface ILoading {
  success: boolean;
  message: string;
}

type Nullable<T> = T | undefined | null;

export interface CallHistoryEntity {
  lead_id: Nullable<number>;
  history_id: Nullable<number>;
  call_start: Nullable<string>;
  call_end: Nullable<string>;
  direction: Nullable<string>;
  agent_name: Nullable<string>;
  dispoistion: Nullable<string>;
  max: Nullable<string>;
  recording_url: Nullable<string>;
}

const CallHistory = ({ source, lead_id }: CallHistoryProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Nullable<string>>(null);
  const [data, setData] = useState<CallHistoryEntity[]>([]);

  // const handleChange = useCallback(
  //   (colorName: string, color?: string) => {
  //     if (color) {
  //       onChange({ ...colorsRef.current, [colorName]: color });
  //     } else {
  //       onChange(_.omit(colorsRef.current, colorName));
  //     }
  //   },
  //   [colorsRef, onChange],
  // );

  const prevLeadId = useRef<number>(0);
  useEffect(() => {
    const leadId: number = parseInt(lead_id);
    if (leadId && leadId > 0) {
      if (!prevLeadId.current || prevLeadId.current !== leadId) {
        prevLeadId.current = leadId;
        setLoading(true);
        setError(null);
        axios
          .get(
            `https://m3-dev.53dnorth.com/storm/external/integration/calls/history/${leadId}`,
            {
              headers: {
                Authorization: `Bearer e430647f-6715-4c40-bda9-03a1e6a4090f`,
              },
            },
          )
          .then(resp => {
            const { data } = resp;
            setData(data.results);
            setLoading(false);
          })
          .catch(ex => {
            setError(ex.message);
            setLoading(false);
          });
        // setData([{
        //   lead_id: lead_id,
        //   history_id: 29127513,
        //   call_start: '01/08/2023 13:42:40',
        //   call_end: '01/08/2023 13:42:56',
        //   direction: 'Inbound',
        //   agent: 'Anton',
        //   max: 'INHU',
        //   dispoistion: 'Inbound',
        //   recording_url: 'http://www.sousound.com/music/healing/healing_01.mp3'
        // }])
      }
    }
  }, [setLoading, lead_id]);

  return (
    <>
      {loading ? <span>Loading...</span> : null}
      {error ? <span>{error}</span> : null}
      <CallTable data={data} />
    </>
  );
};

interface CallTableProps {
  data: CallHistoryEntity[];
}

const CallTable = ({ data }: CallTableProps): JSX.Element => {
  return (
    <TableRoot>
      <TableHeader>
        <TableHeaderRow>
          <TableHeaderCell>{t`Call Start`}</TableHeaderCell>
          <TableHeaderCell>{t`History ID`}</TableHeaderCell>
          <TableHeaderCell>{t`Lead ID`}</TableHeaderCell>
          <TableHeaderCell>{t`Call End`}</TableHeaderCell>
          <TableHeaderCell>{t`Direction`}</TableHeaderCell>
          <TableHeaderCell>{t`Agent`}</TableHeaderCell>
          <TableHeaderCell>{t`Disposition`}</TableHeaderCell>
          <TableHeaderCell>{t`Max`}</TableHeaderCell>
          <TableHeaderCell>{t`Recording`}</TableHeaderCell>
        </TableHeaderRow>
      </TableHeader>
      <TableBody>
        {data.map(r => (
          <CallRow key={r.history_id} data={r} />
        ))}
      </TableBody>
    </TableRoot>
  );
};

interface CallRowProps {
  data: CallHistoryEntity;
}

const CallRow = memo(function CallRow({ data }: CallRowProps) {
  return (
    <TableBodyRow>
      <TableBodyCell>{data.call_start}</TableBodyCell>
      <TableBodyCell>{data.history_id}</TableBodyCell>
      <TableBodyCell>{data.lead_id}</TableBodyCell>
      <TableBodyCell>{data.call_end}</TableBodyCell>
      <TableBodyCell>{data.direction}</TableBodyCell>
      <TableBodyCell>{data.agent_name}</TableBodyCell>
      <TableBodyCell>{data.dispoistion}</TableBodyCell>
      <TableBodyCell>{data.max}</TableBodyCell>
      <TableBodyCell>
        {data.recording_url ? (
          <audio src={data.recording_url} preload="auto" controls={true} />
        ) : null}
      </TableBodyCell>
    </TableBodyRow>
  );
});

// eslint-disable-next-line import/no-default-export -- deprecated usage
export default React.memo(CallHistory);
