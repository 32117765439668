var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./cljs.reader.js");
'use strict';var rA=function(a,b,c,d,e){this.ab=a;this.qa=b;this.K=c;this.Na=d;this.J=e;this.C=2565220111;this.I=0},sA=new $CLJS.M("tailrecursion.priority-map","not-found","tailrecursion.priority-map/not-found",-436727517);$CLJS.g=rA.prototype;$CLJS.g.na=function(a,b){return $CLJS.J.g(this.qa,b)};$CLJS.g.$=function(a,b,c){return $CLJS.J.j(this.qa,b,c)};$CLJS.g.da=function(a,b,c){return $CLJS.sh(b,function(d){return $CLJS.sh(b,$CLJS.yh,""," ","",c,d)},"#tailrecursion.priority-map {",", ","}",c,this)};$CLJS.g.O=function(){return this.K};$CLJS.g.ha=function(){return $CLJS.D(this.qa)};
$CLJS.g.pc=function(){if(0===$CLJS.D(this.qa))return null;var a=$CLJS.A(this.ab),b=$CLJS.A($CLJS.Fb(a));return $CLJS.n(this.Na)?new $CLJS.P(null,2,5,$CLJS.Q,[b,this.qa.h?this.qa.h(b):this.qa.call(null,b)],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.Eb(a)],null)};
$CLJS.g.qc=function(){if(0===$CLJS.D(this.qa))throw Error("Can't pop empty priority map");var a=$CLJS.A(this.ab),b=$CLJS.Fb(a),c=$CLJS.A(b);a=$CLJS.Eb(a);return $CLJS.E.g($CLJS.D(b),1)?new rA($CLJS.Jk.g(this.ab,a),$CLJS.Jk.g(this.qa,c),this.K,this.Na,null):new rA($CLJS.R.j(this.ab,a,$CLJS.Kk.g(b,c)),$CLJS.Jk.g(this.qa,c),this.K,this.Na,null)};
$CLJS.g.oc=function(){var a=this,b=this;return $CLJS.n(a.Na)?$CLJS.y(function(){return function e(d){return new $CLJS.ne(null,function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.A(l),t=$CLJS.I(m,0,null),u=$CLJS.I(m,1,null);if(k=$CLJS.y(function(v,x,z,C,G,K,S){return function ha(Z){return new $CLJS.ne(null,function(){return function(){for(;;){var ra=$CLJS.y(Z);if(ra){if($CLJS.Ad(ra)){var Na=$CLJS.lc(ra),zb=$CLJS.D(Na),Pa=$CLJS.qe(zb);a:for(var Za=0;;)if(Za<zb){var eb=$CLJS.kd(Na,Za);eb=
new $CLJS.P(null,2,5,$CLJS.Q,[eb,a.qa.h?a.qa.h(eb):a.qa.call(null,eb)],null);Pa.add(eb);Za+=1}else{Na=!0;break a}return Na?$CLJS.te($CLJS.ve(Pa),ha($CLJS.mc(ra))):$CLJS.te($CLJS.ve(Pa),null)}Pa=$CLJS.A(ra);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[Pa,a.qa.h?a.qa.h(Pa):a.qa.call(null,Pa)],null),ha($CLJS.Lc(ra)))}return null}}}(v,x,z,C,G,K,S),null,null)}}(f,m,t,u,l,k,b)(u)))return $CLJS.gf.g(k,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null}},null,null)}($CLJS.$b(a.ab))}()):$CLJS.y(function(){return function e(d){return new $CLJS.ne(null,
function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.A(l),t=$CLJS.I(m,0,null),u=$CLJS.I(m,1,null);if(k=$CLJS.y(function(v,x,z,C,G,K,S){return function ha(Z){return new $CLJS.ne(null,function(ra,Na,zb){return function(){for(;;){var Pa=$CLJS.y(Z);if(Pa){if($CLJS.Ad(Pa)){var Za=$CLJS.lc(Pa),eb=$CLJS.D(Za),Sa=$CLJS.qe(eb);a:for(var mb=0;;)if(mb<eb){var Ja=$CLJS.kd(Za,mb);Sa.add(new $CLJS.P(null,2,5,$CLJS.Q,[Ja,zb],null));mb+=1}else{Za=!0;break a}return Za?$CLJS.te($CLJS.ve(Sa),ha($CLJS.mc(Pa))):
$CLJS.te($CLJS.ve(Sa),null)}Sa=$CLJS.A(Pa);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[Sa,zb],null),ha($CLJS.Lc(Pa)))}return null}}}(v,x,z,C,G,K,S),null,null)}}(f,m,t,u,l,k,b)(u)))return $CLJS.gf.g(k,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null}},null,null)}($CLJS.$b(a.ab))}())};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=$CLJS.Qc(this)};$CLJS.g.V=function(a,b){return $CLJS.Sb(this.qa,b)};$CLJS.g.oa=function(){return $CLJS.od($CLJS.tA,this.K)};
$CLJS.g.Gb=function(a,b){a=this.qa.g?this.qa.g(b,sA):this.qa.call(null,b,sA);if($CLJS.E.g(a,sA))return this;a=this.Na.h?this.Na.h(a):this.Na.call(null,a);var c=this.ab.h?this.ab.h(a):this.ab.call(null,a);return $CLJS.E.g($CLJS.D(c),1)?new rA($CLJS.Jk.g(this.ab,a),$CLJS.Jk.g(this.qa,b),this.K,this.Na,null):new rA($CLJS.R.j(this.ab,a,$CLJS.Kk.g(c,b)),$CLJS.Jk.g(this.qa,b),this.K,this.Na,null)};
$CLJS.g.ma=function(a,b,c){var d=$CLJS.J.j(this.qa,b,null);if($CLJS.n(d)){if($CLJS.E.g(d,c))return this;a=this.Na.h?this.Na.h(c):this.Na.call(null,c);d=this.Na.h?this.Na.h(d):this.Na.call(null,d);var e=$CLJS.J.g(this.ab,d);return $CLJS.E.g($CLJS.D(e),1)?new rA($CLJS.R.j($CLJS.Jk.g(this.ab,d),a,$CLJS.be.g($CLJS.J.j(this.ab,a,$CLJS.bh),b)),$CLJS.R.j(this.qa,b,c),this.K,this.Na,null):new rA($CLJS.R.l(this.ab,d,$CLJS.Kk.g($CLJS.J.g(this.ab,d),b),$CLJS.H([a,$CLJS.be.g($CLJS.J.j(this.ab,a,$CLJS.bh),b)])),
$CLJS.R.j(this.qa,b,c),this.K,this.Na,null)}a=this.Na.h?this.Na.h(c):this.Na.call(null,c);return new rA($CLJS.R.j(this.ab,a,$CLJS.be.g($CLJS.J.j(this.ab,a,$CLJS.bh),b)),$CLJS.R.j(this.qa,b,c),this.K,this.Na,null)};$CLJS.g.Va=function(a,b){return $CLJS.Hd(this.qa,b)};
$CLJS.g.ga=function(){var a=this,b=this;return $CLJS.n(a.Na)?$CLJS.y(function(){return function e(d){return new $CLJS.ne(null,function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.A(l),t=$CLJS.I(m,0,null),u=$CLJS.I(m,1,null);if(k=$CLJS.y(function(v,x,z,C,G,K,S){return function ha(Z){return new $CLJS.ne(null,function(){return function(){for(;;){var ra=$CLJS.y(Z);if(ra){if($CLJS.Ad(ra)){var Na=$CLJS.lc(ra),zb=$CLJS.D(Na),Pa=$CLJS.qe(zb);a:for(var Za=0;;)if(Za<zb){var eb=$CLJS.kd(Na,Za);eb=
new $CLJS.P(null,2,5,$CLJS.Q,[eb,a.qa.h?a.qa.h(eb):a.qa.call(null,eb)],null);Pa.add(eb);Za+=1}else{Na=!0;break a}return Na?$CLJS.te($CLJS.ve(Pa),ha($CLJS.mc(ra))):$CLJS.te($CLJS.ve(Pa),null)}Pa=$CLJS.A(ra);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[Pa,a.qa.h?a.qa.h(Pa):a.qa.call(null,Pa)],null),ha($CLJS.Lc(ra)))}return null}}}(v,x,z,C,G,K,S),null,null)}}(f,m,t,u,l,k,b)(u)))return $CLJS.gf.g(k,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null}},null,null)}(a.ab)}()):$CLJS.y(function(){return function e(d){return new $CLJS.ne(null,
function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.A(l),t=$CLJS.I(m,0,null),u=$CLJS.I(m,1,null);if(k=$CLJS.y(function(v,x,z,C,G,K,S){return function ha(Z){return new $CLJS.ne(null,function(ra,Na,zb){return function(){for(;;){var Pa=$CLJS.y(Z);if(Pa){if($CLJS.Ad(Pa)){var Za=$CLJS.lc(Pa),eb=$CLJS.D(Za),Sa=$CLJS.qe(eb);a:for(var mb=0;;)if(mb<eb){var Ja=$CLJS.kd(Za,mb);Sa.add(new $CLJS.P(null,2,5,$CLJS.Q,[Ja,zb],null));mb+=1}else{Za=!0;break a}return Za?$CLJS.te($CLJS.ve(Sa),ha($CLJS.mc(Pa))):
$CLJS.te($CLJS.ve(Sa),null)}Sa=$CLJS.A(Pa);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[Sa,zb],null),ha($CLJS.Lc(Pa)))}return null}}}(v,x,z,C,G,K,S),null,null)}}(f,m,t,u,l,k,b)(u)))return $CLJS.gf.g(k,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null}},null,null)}(a.ab)}())};$CLJS.g.P=function(a,b){return new rA(this.ab,this.qa,b,this.Na,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.ma(null,$CLJS.kd(b,0),$CLJS.kd(b,1)):$CLJS.fb($CLJS.pb,this,b)};
$CLJS.g.call=function(a){switch(arguments.length-1){case 1:return this.h(arguments[1]);case 2:return this.g(arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length-1)].join(""));}};$CLJS.g.apply=function(a,b){return this.call.apply(this,[this].concat($CLJS.db(b)))};$CLJS.g.h=function(a){return this.na(null,a)};$CLJS.g.g=function(a,b){return this.$(null,a,b)};
$CLJS.tA=new rA(function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;a:for(b=$CLJS.y(0<b.length?new $CLJS.w(b.slice(0),0,null):null),d=$CLJS.gl;;)if(b)c=$CLJS.B($CLJS.B(b)),d=$CLJS.R.j(d,$CLJS.A(b),$CLJS.hd(b)),b=c;else break a;return d}(),$CLJS.N,$CLJS.N,$CLJS.Td,null);$CLJS.J.g($CLJS.q($CLJS.qA),"tailrecursion.priority-map");
$CLJS.Oh.v($CLJS.qA,$CLJS.R,"tailrecursion.priority-map",function(a){if($CLJS.xd(a))return $CLJS.eg.g($CLJS.tA,a);throw Error("Priority map literal expects a map for its elements.");});