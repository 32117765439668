import ReactJQCloud from "react-jqcloud";
import IFrame from "./IFrame";
import CallHistory from "./CallHistory";

import "./jqcloud.css";
import CallHistoryCards from "./CallHistoryCards";
import TimelineCards from "./TimelineCards";
import TranscriptChat from "./TranscriptChat";
import Tabs from "./Tabs";
import FeedbackForm from "./FeedbackForm";
import CallQaTable from "./CallQaTable";
import CsiGenerativeAi from "./CsiGenerativeAi";

const removeMatches = (str, matches) => {
  for (const m of matches) {
    str = str.replace(m[0], "");
  }
  return str;
};

const getWidgets = str => {
  const config = {
    tabs: {
      re: /\[tabs\](.*?)\[\/tabs\]/gis,
      map: (m, i) => {
        const reTabs = /\[tab title\="(.*?)"\](.*?)\[\/tab\]/gi;
        const matches = [...m[1].trim().matchAll(reTabs)];

        const out = [];
        const titles = [];
        for (const m1 of matches) {
          const title = m1[1].trim();
          titles.push({
            name: title,
            value: title,
          });
          for (const key of Object.keys(config)) {
            let str = m1[2];
            const matches = [...str.matchAll(config[key].re)];
            if (matches.length > 0) {
              out.push({
                key,
                map: config[key].map,
                matches,
              });
              str = removeMatches(str, matches);
            }
          }
        }
        // const [ lead_id ] = m[1].split(':');

        return (
          <Tabs key={`tabs_${i}`} titles={titles}>
            {out.map(o => {
              return o.matches.map(o.map);
            })}
          </Tabs>
        );
      },
    },
    audioMatches: {
      re: /\[audio\](.*?)\[\/audio\]/gi,
      map: (m, i) => {
        const url = m[1];
        return (
          <audio
            key={`audio_${i}_${url}`}
            src={url}
            controls={true}
            preload="auto"
          />
        );
      },
    },
    iframeMatches: {
      re: /\[iframe\](.*?)\[\/iframe\]/gi,
      map: (m, i) => {
        const url = m[1];
        return <IFrame key={`iframe_${i}_${url}`} url={url} />;
      },
    },
    callHistoryMatches: {
      re: /\[call-history\](.*?)\|(.*?)\[\/call-history\]/gi,
      map: (m, i) => {
        const [source] = (m[1] || "").split(":");
        const [lead_id] = (m[2] || "").split(":");
        return (
          <CallHistoryCards
            key={`call_history_cards${i}_${source}_${lead_id}`}
            source={source}
            lead_id={lead_id}
          />
        );
      },
    },
    callHistoryTableMatches: {
      re: /\[call-history-table\](.*?)|(.*?)\[\/call-history-table\]/gi,
      map: (m, i) => {
        const [source] = (m[1] || "").split(":");
        const [lead_id] = (m[2] || "").split(":");
        return (
          <CallHistory
            key={`call_history${i}_${source}_${lead_id}`}
            source={source}
            lead_id={lead_id}
          />
        );
      },
    },
    csiGenerativeAi: {
      re: /\[csi-generative-ai\](.*?)\|(.*?)\|(.*?)\[\/csi-generative-ai\]/gi,
      map: (m, i) => {
        let [programmes] = m[1].split(":");
        let [dealer_groups] = m[2].split(":");
        let [dealers] = m[3].split(":");
        programmes = programmes === "{{programmes}}" ? null : programmes;
        dealers = dealers === "{{dealers}}" ? null : dealers;
        dealer_groups =
          dealer_groups === "{{dealer_groups}}" ? null : dealer_groups;
        return (
          <CsiGenerativeAi
            key={`csi_generative_ai${i}_${programmes}_${dealer_groups}_${dealers}`}
            programmes={programmes}
            dealer_groups={dealer_groups}
            dealers={dealers}
          />
        );
      },
    },
    wordCloudMatches: {
      re: /\[word-cloud\](.*?)\[\/word-cloud\]/gi,
      map: (m, i) => {
        // const [ lead_id ] = m[1].split(':');
        const word_list = [
          {
            text: "Top",
            weight: 2,
          },
          {
            text: "Communication",
            weight: 1,
          },
          {
            text: "Service",
            weight: 3,
          },
          {
            text: "Car",
            weight: 8,
          },
          {
            text: "Issues",
            weight: 7,
          },
          {
            text: "Customer",
            weight: 2,
          },
          {
            text: "Repair",
            weight: 5,
          },
          {
            text: "Dissatisfied",
            weight: 1,
          },
          {
            text: "Promised",
            weight: 4,
          },
          {
            text: "Availability",
            weight: 4,
          },
          {
            text: "Frustration",
            weight: 9,
          },
        ];
        return (
          <div
            key={`word_cloud${i}`}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <ReactJQCloud word_array={word_list} />
          </div>
        );
      },
    },
    timelineCardsMatches: {
      re: /\[timeline-cards\](.*?)\[\/timeline-cards\]/gi,
      map: (m, i) => {
        const [lead_id] = m[1].split(":");
        return (
          <TimelineCards
            key={`timeline_cards${i}_${lead_id}`}
            lead_id={lead_id}
          />
        );
      },
    },
    transcriptMatches: {
      re: /\[transcript\](.*?)\[\/transcript\]/gi,
      map: (m, i) => {
        const [lead_id] = m[1].split(":");
        return (
          <TranscriptChat
            key={`transcript_chat${i}_${lead_id}`}
            lead_id={lead_id}
          />
        );
      },
    },
    feedbackFormMatches: {
      re: /\[feedback-form\](.*?)\|(.*?)\[\/feedback-form\]/gi,
      map: (m, i) => {
        const [source] = (m[1] || "").split(":");
        const [lead_id] = (m[2] || "").split(":");
        return (
          <FeedbackForm
            key={`feedback_form${i}_${source}_${lead_id}`}
            source={source}
            lead_id={lead_id}
          />
        );
      },
    },
    callQaMatches: {
      re: /\[call-qa\](.*?)\|(.*?)\[\/call-qa\]/gi,
      map: (m, i) => {
        const [source] = (m[1] || "").split(":");
        const [lead_id] = (m[2] || "").split(":");
        return (
          <CallQaTable
            key={`call_qa${i}_${source}_${lead_id}`}
            source={source}
            lead_id={lead_id}
          />
        );
      },
    },
  };
  const out = [];
  let total = 0;
  for (const key of Object.keys(config)) {
    const matches = [...str.matchAll(config[key].re)];
    total += matches.length;
    if (matches.length > 0) {
      out.push({
        key,
        map: config[key].map,
        matches,
      });
      str = removeMatches(str, matches);
    }
  }
  return { str, total, out };
};

const code = ({ node, inline, className, children, ...props }) => {
  if (typeof children[0] === "string") {
    const { total, out } = getWidgets(children[0]);
    return total > 0 ? (
      <div>
        {out.map(o => {
          return o.matches.map(o.map);
        })}
      </div>
    ) : (
      <code {...props} className={className}>
        {children}
      </code>
    );
  } else {
    return (
      <code {...props} className={className}>
        {children}
      </code>
    );
  }
};

export default code;
