import React, { memo, useEffect, useState, useRef } from "react";
import axios from "axios";
import _ from "lodash";
import ReactJQCloud from "react-jqcloud";
import { CsiGenAiCardCont, WordCloudCont, H2 } from "./CsiGenerativeAi.styled";

type Nullable<T> = T | undefined | null;

export interface CsiGenAiProps {
  programmes: Nullable<string>;
  dealer_groups: Nullable<string>;
  dealers: Nullable<string>;
}

export interface ILoading {
  success: boolean;
  message: string;
}

export interface CsiGenAiAnswer {
  answer: Nullable<string>;
  question: Nullable<string>;
}

export interface CsiGenAiEntity {
  org: Nullable<string>;
  org_id: number;
  branch: Nullable<string>;
  analyse_text: Nullable<string>;
  date: Nullable<string>;
  answers: CsiGenAiAnswer[];
}

const parseMultiParamsStr = (str: Nullable<string>) => {
  return (str || "")
    .replace("\\, and ", "\\, ")
    .replace(" and ", "\\, ")
    .split("\\, ");
};

const CsiGenerativeAi = ({
  programmes,
  dealer_groups,
  dealers,
}: CsiGenAiProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Nullable<string>>(null);
  const [data, setData] = useState<CsiGenAiEntity[]>([]);
  // console.log(data, 'data')

  // console.log(programmes, 'programmes')
  // console.log(dealers, 'dealers')

  const prevOrg = useRef<Nullable<string>>("0");
  const prevDealerGroups = useRef<Nullable<string>>("0");
  const prevDealers = useRef<Nullable<string>>("0");
  useEffect(() => {
    // console.log('here')
    // console.log(programmes, 'programmes')
    // console.log(dealer_groups, 'dealer_groups')
    // console.log(dealers, 'dealers')
    const hasProgrammes = programmes && programmes.length > 0;
    const hasDealerGroups = dealer_groups && dealer_groups.length > 0;
    const hasDealers = dealers && dealers.length > 0;
    if (hasProgrammes || hasDealerGroups || hasDealers) {
      if (
        !prevOrg.current ||
        prevOrg.current !== programmes ||
        !prevDealerGroups.current ||
        prevDealerGroups.current !== dealer_groups ||
        !prevDealers.current ||
        prevDealers.current !== dealers
      ) {
        prevOrg.current = programmes;
        prevDealerGroups.current = dealer_groups;
        prevDealers.current = dealers;
        setLoading(true);
        setError(null);
        axios
          .post(
            `https://csi-api.genbacx.com/api/integration/csi/generative/ai`,
            {
              programmes:
                programmes && programmes.length > 0
                  ? parseMultiParamsStr(programmes)
                  : undefined,
              dealer_groups:
                dealer_groups && dealer_groups.length > 0
                  ? parseMultiParamsStr(dealer_groups)
                  : undefined,
              dealers:
                dealers && dealers.length > 0
                  ? parseMultiParamsStr(dealers)
                  : undefined,
            },
            {
              headers: {
                Authorization: `Bearer 1f7b6f64-013a-4e14-90e3-1463eb4cf853`,
              },
            },
          )
          .then(resp => {
            let { data } = resp;
            const all = _.find(
              data.results,
              (r: CsiGenAiEntity) => r.branch === "All",
            );
            data = data.results.filter(
              (r: CsiGenAiEntity) => r.branch !== "All",
            );
            if (all) {
              data = [all, ...data];
            }
            setData(data);
            setLoading(false);
          })
          .catch(ex => {
            setError(ex.message);
            setLoading(false);
          });
      }
    } else {
      setData([]);
    }
  }, [setLoading, programmes, dealer_groups, dealers]);

  return (
    <>
      {loading ? <span>Loading...</span> : null}
      {error ? <span>{error}</span> : null}
      <CsiGenAiCont data={data} />
    </>
  );
};

interface CsiGenAiContProps {
  data: CsiGenAiEntity[];
}

const CsiGenAiCont = ({ data }: CsiGenAiContProps): JSX.Element => {
  return (
    <div>
      <CsiGenAiCardCont>
        The comments on this dashboard are generated using Generative Artificial
        Intelligence. All the feedback comments provided by customers are
        scanned by the Genba AI model to identify common issues and areas that
        could be reviewed. Remember Genba AI is still learning about your
        business so can sometimes phrase things incorrectly or type things a
        human wouldn’t but the insight it generates helps identify areas for
        improvement.
        <br />
        <br />
        <i>
          Generative artificial intelligence or generative AI is a type of
          artificial intelligence system capable of generating text, images, or
          other media in response to prompts. Generative AI models learn the
          patterns and structure of their input training data, and then generate
          new data that has similar characteristics.
        </i>
      </CsiGenAiCardCont>
      {data.map((r, i) => (
        <TimelineCard key={`timeline_${i}`} index={i} data={r} />
      ))}
    </div>
  );
};

interface TimelineCardProps {
  index: number;
  data: CsiGenAiEntity;
}

interface WordCloudProps {
  text: string;
  weight: number;
}

function removeCommonWords(input: string) {
  // EEEEEEK Stop words
  const commonWords = [
    "4",
    "a",
    "able",
    "about",
    "above",
    "abroad",
    "according",
    "accordingly",
    "across",
    "actually",
    "adj",
    "after",
    "afterwards",
    "again",
    "against",
    "ago",
    "ahead",
    "ain't",
    "all",
    "allow",
    "allows",
    "almost",
    "alone",
    "along",
    "alongside",
    "already",
    "also",
    "although",
    "always",
    "am",
    "amid",
    "amidst",
    "among",
    "amongst",
    "an",
    "and",
    "another",
    "any",
    "anybody",
    "anyhow",
    "anyone",
    "anything",
    "anyway",
    "anyways",
    "anywhere",
    "apart",
    "appear",
    "appreciate",
    "appropriate",
    "are",
    "aren't",
    "around",
    "as",
    "a's",
    "aside",
    "ask",
    "asking",
    "associated",
    "at",
    "available",
    "away",
    "awfully",
    "b",
    "back",
    "backward",
    "backwards",
    "be",
    "became",
    "because",
    "become",
    "becomes",
    "becoming",
    "been",
    "before",
    "beforehand",
    "begin",
    "behind",
    "being",
    "believe",
    "below",
    "beside",
    "besides",
    "best",
    "better",
    "between",
    "beyond",
    "both",
    "brief",
    "but",
    "by",
    "c",
    "came",
    "can",
    "cannot",
    "cant",
    "can't",
    "caption",
    "cause",
    "causes",
    "certain",
    "certainly",
    "changes",
    "clearly",
    "c'mon",
    "co",
    "co.",
    "com",
    "come",
    "comes",
    "concerning",
    "consequently",
    "consider",
    "considering",
    "contain",
    "containing",
    "contains",
    "corresponding",
    "could",
    "couldn't",
    "course",
    "c's",
    "currently",
    "d",
    "dare",
    "daren't",
    "definitely",
    "described",
    "despite",
    "did",
    "didn't",
    "different",
    "directly",
    "do",
    "does",
    "doesn't",
    "doing",
    "done",
    "don't",
    "down",
    "downwards",
    "during",
    "e",
    "each",
    "edu",
    "eg",
    "eight",
    "eighty",
    "either",
    "else",
    "elsewhere",
    "end",
    "ending",
    "enough",
    "entirely",
    "especially",
    "et",
    "etc",
    "even",
    "ever",
    "evermore",
    "every",
    "everybody",
    "everyone",
    "everything",
    "everywhere",
    "ex",
    "exactly",
    "example",
    "except",
    "f",
    "fairly",
    "far",
    "farther",
    "few",
    "fewer",
    "fifth",
    "first",
    "five",
    "followed",
    "following",
    "follows",
    "for",
    "forever",
    "former",
    "formerly",
    "forth",
    "forward",
    "found",
    "four",
    "from",
    "further",
    "furthermore",
    "g",
    "get",
    "gets",
    "getting",
    "given",
    "gives",
    "go",
    "goes",
    "going",
    "gone",
    "got",
    "gotten",
    "greetings",
    "h",
    "had",
    "hadn't",
    "half",
    "happens",
    "hardly",
    "has",
    "hasn't",
    "have",
    "haven't",
    "having",
    "he",
    "he'd",
    "he'll",
    "hello",
    "help",
    "hence",
    "her",
    "here",
    "hereafter",
    "hereby",
    "herein",
    "here's",
    "hereupon",
    "hers",
    "herself",
    "he's",
    "hi",
    "him",
    "himself",
    "his",
    "hither",
    "hopefully",
    "how",
    "howbeit",
    "however",
    "hundred",
    "i",
    "i'd",
    "ie",
    "if",
    "ignored",
    "i'll",
    "i'm",
    "immediate",
    "in",
    "inasmuch",
    "inc",
    "inc.",
    "indeed",
    "indicate",
    "indicated",
    "indicates",
    "inner",
    "inside",
    "insofar",
    "instead",
    "into",
    "inward",
    "is",
    "isn't",
    "it",
    "it'd",
    "it'll",
    "its",
    "it's",
    "itself",
    "i've",
    "j",
    "just",
    "k",
    "keep",
    "keeps",
    "kept",
    "know",
    "known",
    "knows",
    "l",
    "last",
    "lately",
    "later",
    "latter",
    "latterly",
    "least",
    "less",
    "lest",
    "let",
    "let's",
    "like",
    "liked",
    "likely",
    "likewise",
    "little",
    "look",
    "looking",
    "looks",
    "low",
    "lower",
    "ltd",
    "m",
    "made",
    "mainly",
    "make",
    "makes",
    "many",
    "may",
    "maybe",
    "mayn't",
    "me",
    "mean",
    "meantime",
    "meanwhile",
    "merely",
    "might",
    "mightn't",
    "mine",
    "minus",
    "miss",
    "more",
    "moreover",
    "most",
    "mostly",
    "mr",
    "mrs",
    "much",
    "must",
    "mustn't",
    "my",
    "myself",
    "n",
    "name",
    "namely",
    "nd",
    "near",
    "nearly",
    "necessary",
    "need",
    "needn't",
    "needs",
    "neither",
    "never",
    "neverf",
    "neverless",
    "nevertheless",
    "new",
    "next",
    "nine",
    "ninety",
    "no",
    "nobody",
    "non",
    "none",
    "nonetheless",
    "noone",
    "no-one",
    "nor",
    "normally",
    "not",
    "nothing",
    "notwithstanding",
    "novel",
    "now",
    "nowhere",
    "o",
    "obviously",
    "of",
    "off",
    "often",
    "oh",
    "ok",
    "okay",
    "old",
    "on",
    "once",
    "one",
    "ones",
    "one's",
    "only",
    "onto",
    "opposite",
    "or",
    "other",
    "others",
    "otherwise",
    "ought",
    "oughtn't",
    "our",
    "ours",
    "ourselves",
    "out",
    "outside",
    "over",
    "overall",
    "own",
    "p",
    "particular",
    "particularly",
    "past",
    "per",
    "perhaps",
    "placed",
    "please",
    "plus",
    "possible",
    "presumably",
    "probably",
    "provided",
    "provides",
    "q",
    "que",
    "quite",
    "qv",
    "r",
    "rather",
    "rd",
    "re",
    "really",
    "reasonably",
    "recent",
    "recently",
    "regarding",
    "regardless",
    "regards",
    "relatively",
    "respectively",
    "right",
    "round",
    "s",
    "said",
    "same",
    "saw",
    "say",
    "saying",
    "says",
    "second",
    "secondly",
    "see",
    "seeing",
    "seem",
    "seemed",
    "seeming",
    "seems",
    "seen",
    "self",
    "selves",
    "sensible",
    "sent",
    "serious",
    "seriously",
    "seven",
    "several",
    "shall",
    "shan't",
    "she",
    "she'd",
    "she'll",
    "she's",
    "should",
    "shouldn't",
    "since",
    "six",
    "so",
    "some",
    "somebody",
    "someday",
    "somehow",
    "someone",
    "something",
    "sometime",
    "sometimes",
    "somewhat",
    "somewhere",
    "soon",
    "sorry",
    "specified",
    "specify",
    "specifying",
    "still",
    "sub",
    "such",
    "sup",
    "sure",
    "t",
    "take",
    "taken",
    "taking",
    "tell",
    "tends",
    "th",
    "than",
    "thank",
    "thanks",
    "thanx",
    "that",
    "that'll",
    "thats",
    "that's",
    "that've",
    "the",
    "their",
    "theirs",
    "them",
    "themselves",
    "then",
    "thence",
    "there",
    "thereafter",
    "thereby",
    "there'd",
    "therefore",
    "therein",
    "there'll",
    "there're",
    "theres",
    "there's",
    "thereupon",
    "there've",
    "these",
    "they",
    "they'd",
    "they'll",
    "they're",
    "they've",
    "thing",
    "things",
    "think",
    "third",
    "thirty",
    "this",
    "thorough",
    "thoroughly",
    "those",
    "though",
    "three",
    "through",
    "throughout",
    "thru",
    "thus",
    "till",
    "to",
    "together",
    "too",
    "took",
    "toward",
    "towards",
    "tried",
    "tries",
    "truly",
    "try",
    "trying",
    "t's",
    "twice",
    "two",
    "u",
    "un",
    "under",
    "underneath",
    "undoing",
    "unfortunately",
    "unless",
    "unlike",
    "unlikely",
    "until",
    "unto",
    "up",
    "upon",
    "upwards",
    "us",
    "use",
    "used",
    "useful",
    "uses",
    "using",
    "usually",
    "v",
    "value",
    "various",
    "versus",
    "very",
    "via",
    "viz",
    "vs",
    "w",
    "want",
    "wants",
    "was",
    "wasn't",
    "way",
    "we",
    "we'd",
    "welcome",
    "well",
    "we'll",
    "went",
    "were",
    "we're",
    "weren't",
    "we've",
    "what",
    "whatever",
    "what'll",
    "what's",
    "what've",
    "when",
    "whence",
    "whenever",
    "where",
    "whereafter",
    "whereas",
    "whereby",
    "wherein",
    "where's",
    "whereupon",
    "wherever",
    "whether",
    "which",
    "whichever",
    "while",
    "whilst",
    "whither",
    "who",
    "who'd",
    "whoever",
    "whole",
    "who'll",
    "whom",
    "whomever",
    "who's",
    "whose",
    "why",
    "will",
    "willing",
    "wish",
    "with",
    "within",
    "without",
    "wonder",
    "won't",
    "would",
    "wouldn't",
    "x",
    "y",
    "yes",
    "yet",
    "you",
    "you'd",
    "you'll",
    "your",
    "you're",
    "yours",
    "yourself",
    "yourselves",
    "you've",
    "z",
    "zero",
  ];
  for (const w of commonWords) {
    input = input.replace(new RegExp("\\b(" + w + ")\\b", "ig"), "");
  }
  return input;
}

function weightedrand(min: number = 5, max: number = 10, gamma: number = 1.5) {
  const offset = max - min + 1;
  return Math.floor(min + Math.pow(Math.random(), gamma) * offset);
}

const getWordArr = (answers: string): WordCloudProps[] => {
  let word_array: WordCloudProps[] = [];
  if (answers && answers.length > 0) {
    const wordArr = [
      "Top words for a word cloud based on the comments could include",
      "Top negative words for a word cloud:",
      "Words for a word cloud: ",
      "Top positive words for a word cloud:",
      "cloud:",
      "word",
      "words",
      ": ",
      ".",
      ",",
      "-",
    ];
    for (const w of wordArr) {
      answers = answers.replace(w, "").replace(",", "").trim();
    }
    // let weight = 4
    word_array = removeCommonWords(answers)
      .split(" ")
      .map(t => t.trim())
      .filter(t => t !== "")
      .map(t => {
        // weight++
        // if (weight > 10) {
        //   weight = 5
        // }
        const weight = weightedrand();
        // console.log(weight, 'weight')
        return {
          text: t.trim().replace(/\n/gi, "").replace(/-/gi, ""),
          weight,
        };
      });
  }
  return word_array;
};

const TimelineCard = memo(function Call({ index, data }: TimelineCardProps) {
  // console.log(data, 'data')
  const word_array: WordCloudProps[] = getWordArr(
    _.get(data, "answers.3.answer"),
  );
  const word_array_positive: WordCloudProps[] = getWordArr(
    _.get(data, "answers.5.answer"),
  );
  const word_array_negative: WordCloudProps[] = getWordArr(
    _.get(data, "answers.6.answer"),
  );
  // console.log(word_array)
  // console.log(word_array_positive)
  // console.log(word_array_negative)

  let topics = _.get(data, "answers.0.answer");
  let topicsArr = [];
  let topicsStr = "";
  if (topics) {
    const remove = ["1- Key topics ", "Key topics "];
    for (const r of remove) {
      topics = topics.replace(r, "");
    }
    topicsArr = topics.split(", ");
    for (const t of topicsArr) {
      topicsStr += `${t} `;
    }
  }

  return (
    <div>
      <H2>
        {data.org} - {data.org_id === 0 ? "All" : data.branch}
      </H2>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <CsiGenAiCardCont className="csi-gen-ai">
          <h3>Top Words</h3>
          <WordCloudCont>
            <ReactJQCloud word_array={word_array} options={{ min_weight: 5 }} />
          </WordCloudCont>
        </CsiGenAiCardCont>
        <CsiGenAiCardCont className="csi-gen-ai positive">
          <h3>Top Positive Words</h3>
          <WordCloudCont>
            <ReactJQCloud
              word_array={word_array_positive}
              options={{ min_weight: 5 }}
            />
          </WordCloudCont>
        </CsiGenAiCardCont>
        <CsiGenAiCardCont className="csi-gen-ai negative">
          <h3>Top Negative Words</h3>
          <WordCloudCont>
            <ReactJQCloud
              word_array={word_array_negative}
              options={{ min_weight: 5 }}
            />
          </WordCloudCont>
        </CsiGenAiCardCont>
        {/* <div style={{ display: "flex", justifyContent: "center", width: '100%' }}>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ReactJQCloud word_array={word_array} />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ReactJQCloud word_array={word_array} />
        </div> */}
      </div>
      <CsiGenAiCardCont>
        <div>
          <h4 style={{ whiteSpace: "pre-line" }}>
            What are the key topics from the comments made by dissatisfied
            customers over the past 7 days
          </h4>
          <div>{topicsStr}</div>
        </div>
      </CsiGenAiCardCont>
      <CsiGenAiCardCont>
        <div>
          <h4 style={{ whiteSpace: "pre-line" }}>
            Recommendations to improve Customer Service
          </h4>
          <div>{_.get(data, "answers.4.answer")}</div>
        </div>
      </CsiGenAiCardCont>
      <CsiGenAiCardCont>
        <div>
          <h4 style={{ whiteSpace: "pre-line" }}>
            Summary of the comments made by dissatisfied customers over the past
            7 days
          </h4>
          <div>{_.get(data, "answers.1.answer")}</div>
        </div>
      </CsiGenAiCardCont>
      <CsiGenAiCardCont>
        <div>
          <h4 style={{ whiteSpace: "pre-line" }}>
            Sentiment of the comments made by dissatisfied customers over the
            past 7 days
          </h4>
          <div>{_.get(data, "answers.2.answer")}</div>
        </div>
        {/* <img src={data.icon || ""} alt={data.type || ""} width={25} />
        <TimelineCardSubTitle>{data.created_at}</TimelineCardSubTitle>
        <TimelineCardTitle>
          {data.description}
          {data.result_code ? ` - ${data.result_code}` : ""}
        </TimelineCardTitle> */}
      </CsiGenAiCardCont>
    </div>
  );
});

// eslint-disable-next-line import/no-default-export -- deprecated usage
export default React.memo(CsiGenerativeAi);
