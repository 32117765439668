import styled from "@emotion/styled";
import { css } from "@emotion/react";

const globalStyles = css`
  font-family: var(--default-font-family), sans-serif;
  font-size: 1em;
`;

export const ChatRoot = styled.div`
  ${globalStyles}
  width: 100%;
`;

export const ChatRow = styled.div`
  ${globalStyles}// align-items: center;
`;
export const ChatContRowLeft = styled.div`
  ${globalStyles}
  display: flex;
  flexdirection: row;
`;
export const ChatContRowRight = styled.div`
  ${globalStyles}
  display: flex;
  flex-direction: row-reverse;
`;

export const TextWrap = styled.div`
  ${globalStyles}
  width: 100%;
  overflow: hidden;
  word-break: break-word;
  text-wrap: wrap;
`;
