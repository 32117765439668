import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { color } from "metabase/lib/colors";

const globalStyles = css`
  font-family: var(--default-font-family), sans-serif;
`;

const cellStyles = css`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  white-space: pre-wrap;
`;

const rowStyles = css`
  border-collapse: collapse;
  border-spacing: 0;
  margin: 1em 0;
  font-family: Monaco, monospace;
  font-size: 16px;
  line-height: 1rem;
  text-align: left;
`;

export const TableRoot = styled.table`
  ${globalStyles}
  width: 100%;
`;

export const TableHeader = styled.thead`
  ${globalStyles}
  ${rowStyles}
`;

export const TableHeaderRow = styled.tr`
  ${globalStyles}// align-items: center;
`;

export const TableHeaderCell = styled.td`
  ${globalStyles}
  ${cellStyles};
  // vertical-align: inherit;
  font-weight: bold;
  text-align: -internal-center;
`;

export const TableBody = styled.tbody`
  ${globalStyles}
  ${rowStyles}
`;

export const TableBodyRow = styled.tr`
  // align-items: center;
`;

export interface TableBodyCellProps {
  fontWeight?: number;
}

export const TableBodyCell = styled.td<TableBodyCellProps>`
  ${globalStyles}
  ${cellStyles};
  // color: ${color("text-medium")};
  font-weight: ${props => props.fontWeight};
`;

export const TableBodyCellLabel = styled.span`
  ${globalStyles}// color: ${color("text-light")};
  // margin-left: 0.25rem;
`;

export interface StatusBoxProps {
  opacity?: number;
}

export const QaRow = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

export const StatusCont = styled.div`
  display: flex;
`;

export const StatusBox = styled.div<StatusBoxProps>`
  ${globalStyles}
  font-size: 12px;
  font-weight: 900;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #509ee3;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  opacity: ${props => props.opacity};
  margin-right: 5px;
`;

export const StatusBoxYes = styled(StatusBox)`
  background-color: #43a047;
`;

export const StatusBoxNo = styled(StatusBox)`
  background-color: #d84315;
`;

export const StatusBoxUnclear = styled(StatusBox)`
  background-color: #ff8f00;
`;

export const InlineCont = styled.div`
  display: inline-block;
`;

export const Divider = styled.div`
  border-bottom: 1px solid #eeecec;
  margin-top: 1rem !important;
`;
