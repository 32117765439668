import React, { memo, useEffect, useState, useRef } from "react";
import axios from "axios";
import moment from "moment";
import { CallCard, CallCardTitle } from "./CallHistoryCards.styled";

export interface CallHistoryProps {
  source: string;
  lead_id: string;
}

export interface ILoading {
  success: boolean;
  message: string;
}

type Nullable<T> = T | undefined | null;

// export interface CallHistoryEntity {
//   lead_id: Nullable<number>;
//   history_id: Nullable<number>;
//   call_start: Nullable<string>;
//   call_end: Nullable<string>;
//   direction: Nullable<string>;
//   agent_name: Nullable<string>;
//   result_code: Nullable<string>;
//   max: Nullable<string>;
//   recording_url: Nullable<string>;
// }

export interface CallHistoryEntity {
  unique_id: Nullable<number>;
  dialer_id: Nullable<number>;
  comms_id: Nullable<number>;
  agent: Nullable<string>;
  comms_date: Nullable<string>;
  direction: Nullable<string>;
  duration: Nullable<number>;
  mapped_outcome: Nullable<string>;
  recording_available: Nullable<number>;
  recording_length: Nullable<number>;
  recording_url: Nullable<string>;
}

const CallHistoryCards = ({
  source,
  lead_id,
}: CallHistoryProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Nullable<string>>(null);
  const [data, setData] = useState<CallHistoryEntity[]>([]);

  // const handleChange = useCallback(
  //   (colorName: string, color?: string) => {
  //     if (color) {
  //       onChange({ ...colorsRef.current, [colorName]: color });
  //     } else {
  //       onChange(_.omit(colorsRef.current, colorName));
  //     }
  //   },
  //   [colorsRef, onChange],
  // );

  // console.log(source, 'source')
  // console.log(lead_id, 'lead_id')

  const prevLeadId = useRef<number>(0);
  useEffect(() => {
    const leadId: number = parseInt(lead_id);
    if (leadId && leadId > 0) {
      if (!prevLeadId.current || prevLeadId.current !== leadId) {
        prevLeadId.current = leadId;
        let sourceUrl = null;
        let sourceToken = null;
        switch (source) {
          case "csi":
            sourceUrl = `https://csi-api.genbacx.com/api/integration/csi/comms/${leadId}`;
            sourceToken = "1f7b6f64-013a-4e14-90e3-1463eb4cf853";
            break;
          default:
            sourceUrl = `https://m3-dev.53dnorth.com/storm/external/integration/calls/history/${leadId}`;
            sourceToken = "e430647f-6715-4c40-bda9-03a1e6a4090f";
            break;
        }
        if (!sourceUrl) {
          return;
        }

        setLoading(true);
        setError(null);
        axios
          .get(sourceUrl, {
            headers: {
              Authorization: `Bearer ${sourceToken}`,
            },
          })
          .then(resp => {
            const { data } = resp;
            let dResults = data.results || [];
            dResults = dResults.map(r => {
              r.comms_date = r.comms_date
                ? moment(r.comms_date).format("DD/MM/YYYY HH:mm:ss")
                : null;
              return r;
            });
            setData(dResults);
            setLoading(false);
          })
          .catch(ex => {
            setError(ex.message);
            setLoading(false);
          });
      }
    }
  }, [setLoading, lead_id, source]);

  return (
    <>
      {loading ? <span>Loading...</span> : null}
      {error ? <span>{error}</span> : null}
      <CallTable data={data} />
    </>
  );
};

interface CallTableProps {
  data: CallHistoryEntity[];
}

interface CallProps {
  index: number;
  data: CallHistoryEntity;
}

const CallTable = ({ data }: CallTableProps): JSX.Element => {
  return (
    <div>
      {data.map((r, i) => (
        <Call key={r.comms_id} index={i} data={r} />
      ))}
    </div>
  );
};

const Call = memo(function Call({ index, data }: CallProps) {
  return (
    <CallCard>
      <div className="">
        <CallCardTitle>Call {index + 1}</CallCardTitle>
        <div>
          <b>Call Date:</b> {data.comms_date}
        </div>
        <div>
          <b>Duration:</b> {data.duration} seconds
        </div>
        {/* <div><b>Call ID:</b> {data.comms_id}</div> */}
        {/* <div><b>Lead ID:</b> {data.lead_id}</div> */}
        <div>
          <b>Direction:</b> {data.direction}
        </div>
        <div>
          <b>Agent:</b> {data.agent}
        </div>
        <div>
          <b>Call Outcome:</b> {data.mapped_outcome}
        </div>
        {data.recording_available === 0 ? (
          <div>
            <b>Recording Not Yet Available</b>
          </div>
        ) : data.recording_url ? (
          <div style={{ paddingTop: 12 }}>
            <audio
              src={data.recording_url}
              preload="auto"
              controls={true}
              style={{ width: "100%" }}
            />
          </div>
        ) : null}
      </div>
    </CallCard>
  );
});

/*
const CallTable = ({ data }: CallTableProps): JSX.Element => {
  return (
    <div>
      {data.map((r, i) => (
        <Call key={r.history_id} index={i} data={r} />
      ))}
    </div>
  );
};

const Call = memo(function Call({ index, data }: CallProps) {
  return (
    <CallCard>
      <div className="">
        <CallCardTitle>Call {index + 1}</CallCardTitle>
        <div>
          <b>Call Date:</b> {data.call_start} -{" "}
          {data.call_end?.split(" ").pop()}
        </div>
        <div>
          <b>Call ID:</b> {data.history_id}
        </div>
        <div>
          <b>Direction:</b> {data.direction}
        </div>
        <div>
          <b>Agent:</b> {data.agent_name}
        </div>
        <div>
          <b>Disposition:</b> {data.result_code}
        </div>
        {data.recording_url ? (
          <>
            <audio
              src={data.recording_url}
              preload="auto"
              controls={true}
              style={{ width: "100%" }}
            />
          </>
        ) : null}
      </div>
    </CallCard>
  );
});
*/

// eslint-disable-next-line import/no-default-export -- deprecated usage
export default React.memo(CallHistoryCards);
