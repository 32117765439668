import styled from "@emotion/styled";
import { css } from "@emotion/react";
// import { color } from "metabase/lib/colors";

// const cellStyles = css`
//   padding-left: 1.5rem;
//   padding-right: 1.5rem;
//   white-space: pre-wrap;
// `;

const globalStyles = css`
  font-family: var(--default-font-family), sans-serif;
`;

const rowStyles = css`
  ${globalStyles}
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  overflow: hidden;
  padding: 0.75rem;
  width: 100%;

  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.13);
  background-color: #fff;

  margin-bottom: 12px;

  font-size: 1.143em;
  line-height: 1.602em;
`;

export const CallCard = styled.div`
  ${globalStyles}
  ${rowStyles}
`;

export const CallCardTitle = styled.h2`
  ${globalStyles}
  font-size: 1.375rem !important;
  color: #509ee3;
`;
