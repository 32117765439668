var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var d2,coa,j2,k2,m2,doa,eoa,foa,goa,hoa,joa,koa,loa,ioa,p2,q2;d2=function(a,b){var c=$CLJS.Gd,d=c2;for(a=$CLJS.y(a);;)if(null!=a){d=$CLJS.J.j(d,$CLJS.A(a),c);if(c===d)return b;a=$CLJS.B(a)}else return d};$CLJS.e2=function(a,b,c){return null==c||$CLJS.Hd(a,b)?a:$CLJS.R.j(a,b,c)};$CLJS.f2=function(a,b){return $CLJS.eg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,new $CLJS.h(null,1,[$CLJS.EE,$CLJS.p.h($CLJS.OE())],null)],null),$CLJS.hf.h($CLJS.wW),b)};
$CLJS.g2=function(a,b){a=$CLJS.JW(a,b);return 0<a?a-1:null};$CLJS.h2=function(a,b){if($CLJS.y(b)){if($CLJS.E.g($CLJS.D(b),1))return $CLJS.A(b);a=[" ",(0,$CLJS.ma)(a)," "].join("");return $CLJS.E.g($CLJS.D(b),2)?[$CLJS.p.h($CLJS.A(b)),a,$CLJS.p.h($CLJS.hd(b))].join(""):[$CLJS.Gt(", ",$CLJS.Dt(b)),",",a,$CLJS.p.h($CLJS.id(b))].join("")}return null};
$CLJS.i2=function(a,b,c,d){a=$CLJS.IW(a);b=$CLJS.n(b)?b:-1;var e=$CLJS.KW(a,b);e=!($CLJS.y($CLJS.cG.h(e))||$CLJS.y($CLJS.bG.h(e)));c=$CLJS.VW.l(a,b,$CLJS.Uk,$CLJS.H([c,function(f){return $CLJS.be.g($CLJS.Df(f),$CLJS.wW.h(d))}]));return e?$CLJS.Uk.N((0,$CLJS.VW)(c,b,function(f){return $CLJS.ZW($CLJS.Jk.l(f,$CLJS.TR,$CLJS.H([$CLJS.XE])),$CLJS.IN,function(k){return $CLJS.Tk.g(function(l){return $CLJS.Jk.g(l,$CLJS.XE)},k)})}),$CLJS.uL,$CLJS.Rk.g(function(f){return $CLJS.eg.g($CLJS.Cf,f)},$CLJS.Yk),0,
$CLJS.JW(a,b)+1):c};coa=function(a,b){b=$CLJS.b0(a,b);return $CLJS.n(b)?$CLJS.P1(a,b):null};j2=function(a){return $CLJS.eg.j($CLJS.bh,$CLJS.Sk.h(function(b){return $CLJS.me(b)&&$CLJS.Pk.g(b,$CLJS.cu)}),$CLJS.Xg(a))};k2=function(a){return $CLJS.eg.j($CLJS.N,$CLJS.im(function(b,c){$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.EE);return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)}),$CLJS.cG.h(a))};
$CLJS.l2=function(a,b,c){var d=$CLJS.il.l;b=$CLJS.Va($CLJS.g2(a,b))?function(){var f=$CLJS.TV.h($CLJS.A($CLJS.uL.h(a)));return $CLJS.n(f)?(f=coa(a,f),$CLJS.n(f)?$CLJS.AH(function(k){return $CLJS.E.g($CLJS.$i.h(k),c)},f):null):null}():null;try{var e=$CLJS.B1(a,c)}catch(f){e=null}return d.call($CLJS.il,$CLJS.H([b,e]))};
m2=function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null),d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.yP);d=$CLJS.J.g(d,$CLJS.JN);$CLJS.I(a,2,null);a=$CLJS.n(d)?$CLJS.E.g(d,$CLJS.eL.h(b)):d;return $CLJS.n(a)?a:$CLJS.E.g($CLJS.EA($CLJS.d_,$CLJS.b_)(b),c)};
doa=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null),d=$CLJS.Fe($CLJS.lf(function(e){var f=$CLJS.E.g($CLJS.QZ.h(e),c);return f?m2(a,e):f},b));return $CLJS.n(d)?d:$CLJS.lf(function(e){var f=$CLJS.E.g($CLJS.T.h(e),c);return f?m2(a,e):f},b)};
eoa=function(a,b,c){$CLJS.I(a,0,null);var d=$CLJS.I(a,1,null),e=$CLJS.I(a,2,null),f=$CLJS.Fe($CLJS.lf(function(k){var l=$CLJS.E.g($CLJS.$i.h(k),e);if(l){if(l=$CLJS.Va($CLJS.yP.h(d))){l=$CLJS.UK.h(k);var m=new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.TZ,null,$CLJS.YZ,null],null),null);l=m.h?m.h(l):m.call(null,l)}return $CLJS.n(l)?l:m2(a,k)}return l},b));if($CLJS.n(f))return f;b=$CLJS.n(c)?$CLJS.Fe($CLJS.lf(function(k){return $CLJS.E.g($CLJS.$i.h(k),e)},b)):null;return $CLJS.n(b)?b:$CLJS.Cf};
foa=function(a){var b=n2.g($CLJS.UK.h(a),$CLJS.KZ);return $CLJS.n(b)?b:$CLJS.wE.h(a)};goa=function(a,b){b=$CLJS.Fe($CLJS.Sk.g($CLJS.eL,b));if($CLJS.n(b))if($CLJS.Va($CLJS.B(b)))a=$CLJS.A(b);else{if($CLJS.n(n2.g($CLJS.A(a),$CLJS.nG))){var c=$CLJS.Fe($CLJS.Sk.g(foa,b));c=$CLJS.n(c)?$CLJS.B(c)?null:$CLJS.A(c):null}else c=null;a=$CLJS.n(c)?c:console.warn($CLJS.hi("Ambiguous match! Implement more logic in disambiguate-matches.",new $CLJS.h(null,2,[$CLJS.W,a,$CLJS.HW,b],null)))}else a=null;return a};
hoa=function(a,b){b=$CLJS.Fe($CLJS.Sk.g(function(c){var d=$CLJS.EA($CLJS.d_,$CLJS.b_)(c);$CLJS.n(d)?(c=$CLJS.UK.h(c),d=new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.TZ,null],null),null),c=d.h?d.h(c):d.call(null,c),c=$CLJS.Va(c)):c=d;return c},b));return $CLJS.n(b)?$CLJS.Va($CLJS.B(b))?$CLJS.A(b):goa(a,b):null};
joa=function(a,b){var c=$CLJS.LE(a);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.yP);return $CLJS.n(d)?(b=$CLJS.Fe($CLJS.lf(function(e){return $CLJS.E.g($CLJS.EA($CLJS.d_,$CLJS.b_)(e),d)},b)),$CLJS.n(b)?$CLJS.Va($CLJS.B(b))?$CLJS.A(b):console.warn("Multiple plausible matches with the same :join-alias - more disambiguation needed",new $CLJS.h(null,2,[$CLJS.W,a,ioa,b],null)):null):hoa(a,b)};koa=new $CLJS.M("metabase.lib.equality","no-right","metabase.lib.equality/no-right",2015744769);
loa=new $CLJS.M("metabase.lib.equality","no-left","metabase.lib.equality/no-left",-1403666439);$CLJS.o2=new $CLJS.M(null,"generous?","generous?",1247836118);$CLJS.moa=new $CLJS.M(null,"matching-refs","matching-refs",324693611);ioa=new $CLJS.M(null,"matches","matches",635497998);p2=new $CLJS.M(null,"left","left",-399115937);q2=new $CLJS.M("metabase.lib.equality","different-dispatch-values","metabase.lib.equality/different-dispatch-values",-2107733395);var n2=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.kC],null),$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.equality","\x3d"),function(f,k){f=$CLJS.iC(f);k=$CLJS.iC(k);return $CLJS.Pk.g(f,k)?q2:f},e,a,b,c,d)}();n2.m(null,q2,function(){return!1});
n2.m(null,$CLJS.rB,function(a,b){var c=j2(a),d=j2(b);return $CLJS.E.g(c,d)&&$CLJS.Qe(function(e){return n2.g($CLJS.J.g(a,e),$CLJS.J.g(b,e))},c)});n2.m(null,$CLJS.pB,function(a,b){var c=$CLJS.E.g($CLJS.D(a),$CLJS.D(b));if(c)for(c=$CLJS.y(a),$CLJS.A(c),$CLJS.B(c),c=$CLJS.y(b),$CLJS.A(c),$CLJS.B(c),c=a,a=b;;){c=$CLJS.y(c);b=$CLJS.A(c);var d=$CLJS.B(c);c=b;b=d;a=$CLJS.y(a);d=$CLJS.A(a);a=$CLJS.B(a);c=n2.g(c,d);if($CLJS.n(c)){if(c=$CLJS.td(b))return c;c=b}else return c}else return c});var c2=null;
n2.m(null,$CLJS.$V,function(a,b){var c=c2;c2=new $CLJS.h(null,2,[p2,k2(a),$CLJS.zx,k2(b)],null);try{var d=$CLJS.tI(n2,$CLJS.rB);return d.g?d.g(a,b):d.call(null,a,b)}finally{c2=c}});
n2.m(null,$CLJS.cG,function(a,b){var c=$CLJS.I(a,0,null),d=$CLJS.I(a,1,null),e=$CLJS.I(a,2,null),f=$CLJS.I(b,0,null),k=$CLJS.I(b,1,null),l=$CLJS.I(b,2,null);return(a=$CLJS.E.l(3,$CLJS.D(a),$CLJS.H([$CLJS.D(b)])))?(c=$CLJS.E.g(c,f))?(d=n2.g(d,k),$CLJS.n(d)?$CLJS.n(c2)?$CLJS.E.g(d2(new $CLJS.P(null,2,5,$CLJS.Q,[p2,e],null),loa),d2(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zx,l],null),koa)):$CLJS.E.g(e,l):d):c:a});
n2.m(null,$CLJS.ci,function(a,b){if($CLJS.xd(a)){var c=$CLJS.tI(n2,$CLJS.rB);return c.g?c.g(a,b):c.call(null,a,b)}return $CLJS.wd(a)?(c=$CLJS.tI(n2,$CLJS.pB),c.g?c.g(a,b):c.call(null,a,b)):$CLJS.E.g(a,b)});
$CLJS.r2=function(){function a(f,k,l,m,t){var u=$CLJS.CW(l)?l:$CLJS.vW(l);$CLJS.I(u,0,null);$CLJS.I(u,1,null);var v=$CLJS.I(u,2,null),x=e.j?e.j(u,m,t):e.call(null,u,m,t);return $CLJS.n(x)?x:$CLJS.n($CLJS.n(f)?"number"===typeof v:f)&&(m=$CLJS.Fe($CLJS.Sk.g($CLJS.$i,m)),$CLJS.n(m)&&(k=$CLJS.CW(l)?$CLJS.l2(f,k,v):l,$CLJS.n(k)))?(f=$CLJS.R.j,l=$CLJS.QZ.h(k),k=$CLJS.n(l)?l:$CLJS.T.h(k),u=$CLJS.NE(f.call($CLJS.R,u,2,k),$CLJS.Ve($CLJS.il,new $CLJS.h(null,1,[$CLJS.wB,$CLJS.Bj],null))),e.j?e.j(u,m,t):e.call(null,
u,m,t)):null}function b(f,k,l,m){return e.N?e.N(f,k,l,m,$CLJS.N):e.call(null,f,k,l,m,$CLJS.N)}function c(f,k,l){var m=$CLJS.I(f,0,null);$CLJS.I(f,1,null);var t=$CLJS.I(f,2,null);l=$CLJS.O(l);l=$CLJS.J.g(l,$CLJS.o2);switch(m instanceof $CLJS.M?m.T:null){case "aggregation":return $CLJS.AH(function(u){return $CLJS.E.g($CLJS.UK.h(u),$CLJS.nL)&&$CLJS.E.g($CLJS.i1.h(u),t)},k);case "expression":case "field":switch(k="string"===typeof t?doa(f,k):eoa(f,k,l),$CLJS.D(k)){case 0:return null;case 1:return $CLJS.A(k);
default:return joa(f,k)}default:throw $CLJS.hi("Unknown type of ref",new $CLJS.h(null,1,[$CLJS.W,f],null));}}function d(f,k){return e.j?e.j(f,k,$CLJS.N):e.call(null,f,k,$CLJS.N)}var e=null;e=function(f,k,l,m,t){switch(arguments.length){case 2:return d.call(this,f,k);case 3:return c.call(this,f,k,l);case 4:return b.call(this,f,k,l,m);case 5:return a.call(this,f,k,l,m,t)}throw Error("Invalid arity: "+arguments.length);};e.g=d;e.j=c;e.v=b;e.N=a;return e}();
$CLJS.noa=function(){function a(d,e,f,k){return $CLJS.r2.v(d,e,$CLJS.zZ.j(d,e,f),k)}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();