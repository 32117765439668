import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { color } from "metabase/lib/colors";

const globalStyles = css`
  font-family: var(--default-font-family), sans-serif;
`;

const cellStyles = css`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  white-space: pre-wrap;
`;

const rowStyles = css`
  border-collapse: collapse;
  border-spacing: 0;
  margin: 1em 0;
  font-family: Monaco, monospace;
  font-size: 12.64px;
  line-height: 0.76rem;
  text-align: left;
`;

export const TableRoot = styled.table`
  ${globalStyles}
  width: 100%;
`;

export const TableHeader = styled.thead`
  ${globalStyles}
  ${rowStyles}
`;

export const TableHeaderRow = styled.tr`
  ${globalStyles}// align-items: center;
`;

export const TableHeaderCell = styled.td`
  ${globalStyles}
  ${cellStyles};
  // vertical-align: inherit;
  font-weight: bold;
  text-align: -internal-center;
`;

export const TableBody = styled.tbody`
  ${globalStyles}
  ${rowStyles}
`;

export const TableBodyRow = styled.tr`
  // align-items: center;
`;

export interface TableBodyCellProps {
  fontWeight?: number;
}

export const TableBodyCell = styled.td<TableBodyCellProps>`
  ${globalStyles}
  ${cellStyles};
  // color: ${color("text-medium")};
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: ${props => props.fontWeight};
`;

export const TableBodyCellLabel = styled.span`
  ${globalStyles}// color: ${color("text-light")};
  // margin-left: 0.25rem;
`;
