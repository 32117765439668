var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var FL,HL,JL,ML,RL;$CLJS.CL=function(a){return $CLJS.Wh($CLJS.q($CLJS.kC),a,$CLJS.rG)};$CLJS.DL=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.EL=new $CLJS.M(null,"object-id","object-id",-754527291);FL=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.GL=new $CLJS.M(null,"operators","operators",-2064102509);
HL=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.IL=new $CLJS.M(null,"stage-number","stage-number",-1752729638);JL=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.KL=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.LL=new $CLJS.M(null,"dimensions","dimensions",-254818097);
ML=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.NL=new $CLJS.M("drill-thru","pk","drill-thru/pk",1721977789);$CLJS.OL=new $CLJS.M("drill-thru","fk-details","drill-thru/fk-details",-687193600);$CLJS.PL=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.QL=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);
RL=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.SL=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);$CLJS.X(HL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,$CLJS.CE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tG,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.kE],null)],null)],null));$CLJS.X(RL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,HL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wB,$CLJS.GE],null)],null)],null));$CLJS.X(JL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.nG],null),RL,$CLJS.xE],null));
$CLJS.X(ML,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.nG],null),HL,$CLJS.lL],null));
$CLJS.OG.g($CLJS.nG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.nG],null),HL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ys,$CLJS.lL,$CLJS.xE],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Ds,new $CLJS.h(null,2,[$CLJS.Oi,function(a){return $CLJS.wd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.iC(a)):null},$CLJS.vt,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.nB,ML],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mB,JL],null)],null)],null));$CLJS.vF($CLJS.nG,$CLJS.rG);$CLJS.RE.m(null,$CLJS.nG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.EA($CLJS.Fi,$CLJS.wB)(b);return $CLJS.n(a)?a:$CLJS.dF});$CLJS.CF($CLJS.tB,$CLJS.H([$CLJS.xE]));$CLJS.RE.m(null,$CLJS.tB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.EA($CLJS.Fi,$CLJS.wB)(b);return $CLJS.n(a)?a:$CLJS.dF});
$CLJS.vF($CLJS.tB,$CLJS.rG);$CLJS.X(FL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,$CLJS.CE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.xE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sE,new $CLJS.h(null,1,[$CLJS.Qs,!0],null),$CLJS.xE],null)],null)],null));$CLJS.OG.g($CLJS.cG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Bs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bs,$CLJS.cG],null),FL,$CLJS.ak],null));
$CLJS.RE.m(null,$CLJS.cG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.EA($CLJS.Fi,$CLJS.wB)(b);return $CLJS.n(a)?a:$CLJS.dF});$CLJS.vF($CLJS.cG,$CLJS.rG);$CLJS.CF($CLJS.MF,$CLJS.H([$CLJS.ot,$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VK],null)],null)]));$CLJS.vF($CLJS.MF,$CLJS.rG);
$CLJS.CF($CLJS.iG,$CLJS.H([$CLJS.ot,$CLJS.dF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.TK],null)],null)]));$CLJS.vF($CLJS.iG,$CLJS.rG);
$CLJS.X($CLJS.rG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Es,$CLJS.TE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.xt,function(){return["Valid reference, must be one of these clauses: ",$CLJS.Gt(", ",$CLJS.$G($CLJS.Jd,$CLJS.nl.g($CLJS.q($CLJS.kC),$CLJS.rG)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.CL(a)}],null)],null));